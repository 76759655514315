import { useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_MUTATION_CREATE_ACCOUNT } from "app/queries";
import { routes } from "app/routes";

import { ProductFeature } from "@app/shared/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormInputCheckbox } from "components/FormInputCheckbox";
import { FormInputText } from "components/FormInputText";
import PageWrapper from "components/PageWrapper";
import { scrollToFirstError } from "components/scrollToFirstError";
import { selectUser, updateAuthToken } from "features/auth/auth";
import { NavLink } from "features/navigation/NavLink";
import { useAffiliatePartnerTracking } from "hooks/useAffiliatePartnerTracking";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { useOptimizelyTrackOnLoad } from "hooks/useOptimizelyTrack";
import _, { startCase } from "lodash";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import * as z from "zod";
import { SignupProgressBar } from "./SignupProgressBar";
import { theme } from "app/theme";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "1000px",
    },
    formField: {
        maxWidth: 600,
        marginBottom: theme.spacing(2),
    },
    errorMessage: {
        marginTop: theme.spacing(2),
    },
    continueButton: {
        marginBottom: theme.spacing(2),
    },
}));

export interface CreateAccountProps {
    allowedEmail?: string;
    analyticsData?: Object;
    analyticsPrefix: string;
    optimizelyEventKey: string;
}

export const CreateAccount = (props: CreateAccountProps) => {
    const classes = useStyles();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const nameFromUrl = queryParams.get("name");
    const formattedNameFromUrl = nameFromUrl ? startCase(nameFromUrl) : "";
    const emailFromUrl = queryParams.get("email");

    const affiliatePartner = useAffiliatePartnerTracking();

    const user = useSelector(selectUser);
    const isActiveMember = user?.features?.includes(ProductFeature.CORE_MEMBERSHIP);

    useLogEventOnLoad(`${props.analyticsPrefix}.account.load`, props.analyticsData);

    useOptimizelyTrackOnLoad(props.optimizelyEventKey);

    const dispatch = useDispatch();
    const [createAccountMutation, { loading: isSaving, error: saveError }] = useMutation(
        GRAPHQL_MUTATION_CREATE_ACCOUNT,
        {
            awaitRefetchQueries: true,
            refetchQueries: ["Me"],
            update: (cache, { data: { signup } }) => {
                dispatch(updateAuthToken(signup));
            },
        },
    );

    const createAccountSchema = z.object({
        userProfile: z.object({
            fullName: z
                .string()
                .min(1, "Please complete this field")
                .max(100, "Name must be less than 100 characters"),
            timeZone: z.string(),
        }),
        email: z
            .string()
            .min(1, "Please complete this field")
            .email("Please enter a valid email address"),
        password: z
            .string()
            .min(1, "Please complete this field")
            .min(6, "Password must be 6 or more characters"),
        acceptedTermsOfService: z
            .boolean()
            .refine((v) => v, {
                message: "Please accept the legal stuff above before continuing",
            })
            .transform((accepted) => (accepted ? DateTime.local().toJSDate() : false)),
    });
    type CreateAccountInput = z.TypeOf<typeof createAccountSchema>;

    // Fetching the email from session storage if legacyPathA is false
    const storedEmail = sessionStorage.getItem("userEmail") || null;

    const {
        control,
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit,
    } = useForm<CreateAccountInput>({
        resolver: zodResolver(createAccountSchema),
        defaultValues: {
            email: emailFromUrl || props.allowedEmail || storedEmail || "",
            password: "",
            acceptedTermsOfService: false,
            userProfile: {
                fullName: formattedNameFromUrl || "",
                timeZone: DateTime.local().zoneName,
            },
        },
    });

    const onCreateAccount: SubmitHandler<CreateAccountInput> = (values) => {
        analyticsTrack(`${props.analyticsPrefix}.account.submit`, props.analyticsData);

        createAccountMutation({
            variables: {
                ...values,
                referredByPartnerId: affiliatePartner?.partnerId,
            },
        }).catch(() => {
            // error is surfaced through "saveError"
        });
    };

    // work around to modify error messaging on
    // zod literal on terms of service checkbox
    const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
        if (issue.code === z.ZodIssueCode.invalid_literal) {
            return {
                message: `Please accept the legal stuff above before continuing`,
            };
        }
        return { message: ctx.defaultError };
    };
    z.setErrorMap(customErrorMap);

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            scrollToFirstError();
        }
    }, [errors]);

    const renderCourseWelcomeMessage = () => (
        <Box
            sx={{
                borderRadius: "30px",
                backgroundColor: theme.palette.neutralWarm,
                textAlign: "center",
                padding: theme.spacing(2, 4, 2, 4),
                mb: 1,
            }}
        >
            <Typography variant="h3" sx={{ mb: 0 }}>
                Welcome 🎉
            </Typography>
            <Typography variant="body1" sx={{ mb: 0 }}>
                Create your account to access the course. If you already have a Banyan account, log
                in with the link at the bottom of the page.
            </Typography>
        </Box>
    );

    const courseSignupUrlPattern = /\/app\/courses\/[^/]+\/enroll/;
    const isInCourseSignupFlow = courseSignupUrlPattern.test(location.pathname);

    return (
        <>
            {!isActiveMember && <SignupProgressBar currentStep={0} />}
            <PageWrapper className={classes.root}>
                {isInCourseSignupFlow && renderCourseWelcomeMessage()}

                <Typography variant="h1">Create your account</Typography>

                <FormInputText
                    name="userProfile.fullName"
                    data-testid="fullNameField"
                    control={control}
                    label="Full name"
                    className={classes.formField}
                />

                <FormInputText
                    id="username"
                    name="email"
                    data-testid="emailField"
                    control={control}
                    label="Your email"
                    className={classes.formField}
                />

                <FormInputText
                    id="password"
                    name="password"
                    data-testid="passwordField"
                    control={control}
                    label="Password"
                    type="password"
                    className={classes.formField}
                />

                <FormInputCheckbox
                    control={control}
                    name={"acceptedTermsOfService"}
                    data-testid="termsAndConditionsField"
                    error={errors.acceptedTermsOfService}
                    label={
                        <Typography variant="body1" style={{ marginBottom: 0 }}>
                            I agree to the{" "}
                            <NavLink to={routes.termsOfService()} target="_blank">
                                Terms &amp; Conditions
                            </NavLink>
                            {" and "}
                            <NavLink to={routes.privacyPolicy()} target="_blank">
                                Privacy Policy
                            </NavLink>
                            .
                        </Typography>
                    }
                />

                <Button
                    variant="primary"
                    data-testid="createAccountButton"
                    onClick={handleSubmit(onCreateAccount)}
                    disabled={isSaving}
                    className={classes.continueButton}
                >
                    Create account
                </Button>

                <Typography variant="body1" sx={{ mb: 0 }}>
                    <NavLink
                        to={routes.login()}
                        redirectState={{
                            from: location.pathname,
                            params: location.search,
                        }}
                        color="primary"
                        data-testid="existingAccountLink"
                    >
                        Already have an account? Sign in
                    </NavLink>
                </Typography>

                {saveError && (
                    <Typography variant="body2" color="error" className={classes.errorMessage}>
                        {saveError.message}
                    </Typography>
                )}
            </PageWrapper>
        </>
    );
};
