import _ from "lodash";
import { RequireAccountCreation } from "../signup/RequireAccountCreation";
import { useParams } from "react-router";
import { CourseStripeClientSecretProvider } from "./CourseStripeClientSecretProvider";
import { Course, CourseAvailability } from "@app/shared/types";
import { GRAPHQL_QUERY_COURSE } from "app/queries";
import { useQuery } from "@apollo/client";
import LoadingPage from "features/pages/LoadingPage";
import PageWrapper from "components/PageWrapper";
import { Box, Typography } from "@mui/material";
import { SignupProgressBar } from "features/signup/SignupProgressBar";
import { theme } from "app/theme";
import { routes } from "app/routes";
import { NavLink } from "features/navigation/NavLink";
import { useQueryParams } from "hooks/useQueryParams";

export const CourseSignupFlow = () => {
    const { courseId } = useParams<{ courseId: string }>();

    const query = useQueryParams();
    const allowRegistration = query.allowRegistration === "true";

    const { data: courseData, loading: courseLoading } = useQuery(GRAPHQL_QUERY_COURSE, {
        variables: { id: courseId },
    });

    if (courseLoading) {
        return <LoadingPage />;
    }

    const course = courseData?.course as Course;

    if (course.availability === CourseAvailability.CLOSED && !allowRegistration) {
        return (
            <>
                <SignupProgressBar currentStep={0} />
                <PageWrapper>
                    <Box
                        sx={{
                            borderRadius: "30px",
                            backgroundColor: theme.palette.neutralWarm,
                            textAlign: "center",
                            padding: theme.spacing(2, 4, 2, 4),
                            mb: 1,
                        }}
                    >
                        <Typography variant="h3" sx={{ mb: 0 }}>
                            Registrations for "{course.name}" are closed
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 0 }}>
                            Please <NavLink to={routes.contactUs()}>contact support</NavLink> if you
                            have any questions.
                        </Typography>
                    </Box>
                </PageWrapper>
            </>
        );
    }

    const analyticsData = course;

    return (
        <RequireAccountCreation
            analyticsPrefix="courses.signup"
            analyticsData={analyticsData}
            optimizelyEventKey="explorerSignupViewed"
        >
            <CourseStripeClientSecretProvider course={course} />
        </RequireAccountCreation>
    );
};
