import { useQuery } from "@apollo/client";
import { CONTACT_EMAIL } from "@app/shared/constants";
import { Session } from "@app/shared/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_QUERY_SUB_REQUESTED_SESSIONS } from "app/queries";
import { showSnackbarAlertOnRedirect } from "components/AlertSnackBar";
import DocumentContext from "components/DocumentContext";
import { GenericErrorPage } from "components/GenericErrorPage";
import PageWrapper from "components/PageWrapper";
import {
    selectIsAdmin,
    selectIsTeacher,
    selectLoggedInTeacherId,
    selectUserId,
} from "features/auth/auth";
import CommunityEventCard from "features/member/community/CommunityEventCard";
import LoadingPage from "features/pages/LoadingPage";
import { useUserTimezone } from "hooks/useUserTimezone";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        paddingTop: 0,
        minHeight: "40vh",
    },
    cardsContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gap: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

export const SubstituteRequestsPage = () => {
    const isTeacher = useSelector(selectIsTeacher);
    const isAdmin = useSelector(selectIsAdmin);
    const loggedInTeacherId = useSelector(selectLoggedInTeacherId);
    const loggedInAdminId = useSelector(selectUserId);
    const timezone = useUserTimezone();
    const classes = useStyles();

    const {
        data: subSessions,
        loading: subSessionsLoading,
        error: subSessionsError,
    } = useQuery(GRAPHQL_QUERY_SUB_REQUESTED_SESSIONS, {
        fetchPolicy: "network-only",
        variables: { teacherId: loggedInTeacherId || loggedInAdminId },
        skip: !isTeacher && !isAdmin,
    });

    if (!isTeacher && !isAdmin) {
        return (
            <PageWrapper data-testid="substituteRequestsPage" className={classes.pageWrapper}>
                <DocumentContext addBrandNameSuffix title="Substitute Requests" />
                <Typography variant="h2">Access Restricted</Typography>
                <Typography variant="body1">
                    You do not have the necessary permissions to view this page. If you believe this
                    is an error, please contact us through {CONTACT_EMAIL}.
                </Typography>
            </PageWrapper>
        );
    }

    const sessions: Session[] = subSessions?.sessionsWithSubRequestedByTeacherId;

    if (subSessionsError) {
        return <GenericErrorPage />;
    }

    if (subSessionsLoading || !subSessions) {
        return <LoadingPage />;
    }

    if (!sessions || sessions.length === 0) {
        return (
            <PageWrapper data-testid="substituteRequestsPage" className={classes.pageWrapper}>
                <DocumentContext addBrandNameSuffix title="Substitute Requests" />
                <Typography variant="h2">Substitute requests</Typography>
                <Typography variant="body1">Great! No substitutes needed at the moment.</Typography>
            </PageWrapper>
        );
    }

    const mySubRequestedSessions = sessions.filter((session) =>
        session.teachers.some((teacher) => teacher.id === loggedInTeacherId),
    );

    const otherSubRequestedSessions = sessions.filter(
        (session) => !session.teachers.some((teacher) => teacher.id === loggedInTeacherId),
    );

    const handleReload = () => {
        analyticsTrack("substitutionConfirmed", { teacherId: loggedInTeacherId });
        showSnackbarAlertOnRedirect(
            'Substitution confirmed. Check "My Schedule" in your dashboard.',
        );
        window.location.reload();
    };

    return (
        <>
            <PageWrapper data-testid="substituteRequestsPage" className={classes.pageWrapper}>
                <DocumentContext addBrandNameSuffix title="Substitute Requests" />
                <Typography variant="h2">Substitute requests</Typography>

                {mySubRequestedSessions.length > 0 && (
                    <>
                        <Typography variant="h3">My Sub-Requested Sessions</Typography>
                        <div className={classes.cardsContainer}>
                            {mySubRequestedSessions.map((session) => (
                                <CommunityEventCard
                                    key={session.id}
                                    event={session}
                                    timezone={timezone}
                                    onConfirmSubstitute={handleReload}
                                    userRsvpedToSession={false}
                                    showSpecificDate={true}
                                />
                            ))}
                        </div>
                    </>
                )}
                {otherSubRequestedSessions.length > 0 && (
                    <>
                        <Typography variant="h3">Other Teachers' Sub-Requested Sessions</Typography>
                        <div className={classes.cardsContainer}>
                            {otherSubRequestedSessions.map((session) => (
                                <CommunityEventCard
                                    key={session.id}
                                    event={session}
                                    timezone={timezone}
                                    onConfirmSubstitute={handleReload}
                                    userRsvpedToSession={false}
                                    showSpecificDate={true}
                                />
                            ))}
                        </div>
                    </>
                )}
            </PageWrapper>
        </>
    );
};
