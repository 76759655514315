import { Avatar, Box, Divider, Typography } from "@mui/material";
import { theme } from "app/theme";
import { LinkButton } from "features/navigation/LinkButton";

interface ExampleQuestionCardProps {
    memberName: string;
    question: string;
    numberOfResponses: number;
    postLink: string;
    userPictureUrl?: string;
}

export const ExampleQuestionCard = (props: ExampleQuestionCardProps) => {
    const { memberName, question, numberOfResponses, postLink, userPictureUrl } = props;

    return (
        <Box
            sx={{
                border: theme.border.thin,
                borderRadius: `${theme.borderRadius.default}px`,
                px: 3,
                py: 2,
                width: {
                    xs: "100%",
                    lg: "45%",
                },
                height: "fit-content",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 1,
                }}
            >
                <Avatar src={userPictureUrl} sx={{ width: 25, height: 25, mr: 1 }} />
                <Typography variant="caption" sx={{ mb: 0, fontWeight: 700 }}>
                    {memberName}
                </Typography>
                <Typography variant="body3" sx={{ ml: 1, color: theme.palette.grey700, mb: 0 }}>
                    Member
                </Typography>
            </Box>
            <Typography variant="caption" sx={{ whiteSpace: "pre-line" }}>
                {question}
            </Typography>
            <Divider sx={{ mt: 1.5, mb: 0.5 }} />
            <Box sx={{ display: "flex", justifyContent: "space-between", pt: 1 }}>
                <Box>
                    <Typography
                        variant="caption"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme.palette.grey700,
                            gap: 1,
                            mb: 0,
                        }}
                    >
                        <span
                            className="material-symbols-rounded"
                            style={{ fontSize: theme.typography.pxToRem(18) }}
                        >
                            chat
                        </span>{" "}
                        +{numberOfResponses} responses
                    </Typography>
                </Box>
                <LinkButton variant="tertiary" to={postLink} openInNewTab>
                    View full post
                </LinkButton>
            </Box>
        </Box>
    );
};
