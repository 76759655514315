import { Sangha } from "@app/shared/types";
import { dateTimeFromString } from "@app/shared/utils";
import { Button, Typography } from "@mui/material";
import LoadingBar from "components/LoadingBar";
import { SanghaDayAndTimeDescription } from "features/sangha/SanghaDayAndTimeDescription";
import { DateTime } from "luxon";
import makeStyles from "@mui/styles/makeStyles";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { FixedCTAContainer } from "features/signup/FixedCTAContainer";

interface InterestGroupCommitmentDetailInfoProps {
    isDropIn: boolean;
    interestGroup: Sangha;
    timezone: string;
    onCommitmentConfirmation: (isDropIn: boolean) => void;
    isSubmitting: boolean;
}

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingBottom: theme.spacing(14),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(8),
        },
    },
    numberCircle: {
        position: "absolute",
        top: theme.spacing(0.5),
        left: 0,
        transform: "translate(-50%, -50%)",
        width: 50,
        height: 50,
        borderRadius: theme.borderRadius.circular,
        backgroundColor: theme.palette.neutralWhite,
        textAlign: "center",
        lineHeight: "50px",
        border: `1px solid ${theme.palette.grey200}`,
        color: theme.palette.grey900,
        [theme.breakpoints.down("md")]: {
            lineHeight: "45px",
            height: 45,
            width: 45,
            left: theme.spacing(1),
        },
    },
    subtitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    baseCard: {
        position: "relative",
        borderRadius: theme.borderRadius.default,
        padding: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    primaryCardBackground: {
        backgroundColor: theme.palette.neutralWarm,
    },
    noMargin: {
        margin: 0,
    },
}));

export const InterestGroupCommitmentDetailInfo = (
    props: InterestGroupCommitmentDetailInfoProps,
) => {
    const { isDropIn, interestGroup, timezone, onCommitmentConfirmation, isSubmitting } = props;

    const classes = useStyles();

    const testVersion = useFeatureFlagVariantKey("aa-test") === "test";
    const control = useFeatureFlagVariantKey("aa-test") === "control";

    return (
        <div className={classes.contentContainer}>
            <Typography variant="h2">What you need to know</Typography>
            <Typography variant="h4" className={classes.subtitle}>
                To ensure a great group experience, please note:
            </Typography>
            <div className={`${classes.baseCard} ${classes.primaryCardBackground}`}>
                <Typography variant="body1" className={classes.numberCircle}>
                    1
                </Typography>
                <Typography variant="body1" sx={{ mb: 0 }}>
                    Each group session format is:
                    <ul className={classes.noMargin}>
                        <li>5 minutes welcome</li>
                        <li>10-15 minutes meditation</li>
                        <li>10-15 minutes short teaching and introduction of today's prompt</li>
                        <li>25 minutes sharing with your pod (breakout room)</li>
                        <li>10 minutes whole group sharing and Q&A</li>
                    </ul>
                </Typography>
            </div>
            <div className={`${classes.baseCard} ${classes.primaryCardBackground}`}>
                <span className={classes.numberCircle}>2</span>
                <Typography variant="body1" sx={{ mb: 0 }}>
                    All group sessions are on a weekly rolling basis. This means people can join the
                    group at any time. There are no start and end dates for a group.
                </Typography>
            </div>
            <div className={`${classes.baseCard} ${classes.primaryCardBackground}`}>
                <span className={classes.numberCircle}>3</span>
                <Typography variant="body1" sx={{ mb: 0 }}>
                    {isDropIn
                        ? "You’ll be in a pod (breakout room) with other drop-ins for the 30-minutes sharing section each week. This means you’ll connect with different people each time you join."
                        : "We aim to keep your dedicated pod (breakout room) to the same four people each week. And, this is not always possible because people do exit groups and some changes are inevitable."}
                </Typography>
            </div>
            <div className={`${classes.baseCard} ${classes.primaryCardBackground}`}>
                <span className={classes.numberCircle}>4</span>
                <Typography variant="body1" sx={{ mb: 0 }}>
                    This group meets weekly on{" "}
                    <SanghaDayAndTimeDescription
                        sangha={interestGroup}
                        userTimeZone={timezone}
                        renderTimeZone={true}
                    />
                    .{" "}
                    {DateTime.fromISO(interestGroup.firstSessionDate) > DateTime.now() && (
                        <>
                            The first session is on{" "}
                            {dateTimeFromString(
                                interestGroup.firstSessionDate,
                                timezone,
                            ).toLocaleString(DateTime.DATE_FULL)}
                            .{" "}
                        </>
                    )}
                </Typography>
            </div>
            <FixedCTAContainer>
                {testVersion || control ? (
                    <Button
                        variant="primary"
                        onClick={() => !isSubmitting && onCommitmentConfirmation(isDropIn)}
                        data-testid="confirmCommitmentButton"
                        disabled={isSubmitting}
                    >
                        I understand
                    </Button>
                ) : (
                    <Button
                        variant="primary"
                        onClick={() => !isSubmitting && onCommitmentConfirmation(isDropIn)}
                        data-testid="confirmCommitmentButton"
                        disabled={isSubmitting}
                    >
                        I understand
                    </Button>
                )}
            </FixedCTAContainer>
            {isSubmitting && <LoadingBar />}
        </div>
    );
};
