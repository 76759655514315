import { useApolloClient } from "@apollo/client";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, Theme, ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import analyticsInstance from "app/analytics/analytics";
import { isBrowser } from "app/analytics/isBrowser";
import { analyticsTrack } from "app/analytics/track";
import { generateId } from "app/generate-id";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { getUtmParameters, trackUtmParameters } from "app/utmParameters";
import { GenericErrorPage } from "components/GenericErrorPage";
import { setReferralCode, setUtmParameters } from "features/navigation/navigationSlice";
import { useQueryParams } from "hooks/useQueryParams";
import MainContainer from "MainContainer";
import { useEffect, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useAnalytics } from "use-analytics";
import { theme } from "./app/theme";
import {
    fetchUser,
    refreshAuthToken,
    selectAuthToken,
    selectRefreshToken,
    setSimulateMember,
} from "./features/auth/auth";
import { Snackbar } from "@mui/material";
import { SnackbarProvider } from "components/SnackbarContext";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

export const App = () => {
    const dispatch = useDispatch();

    const authToken = useSelector(selectAuthToken);
    const refreshToken = useSelector(selectRefreshToken);
    const apollo = useApolloClient();
    const reactRouterLocation = useLocation();
    const analytics = useAnalytics();

    const query = useQueryParams();
    const utmParameters = useMemo(() => getUtmParameters(query), [query]);

    // Anon ID
    const anonID = analyticsInstance.user().anonymousId;

    // PostHog Settings
    const postHogKey: string = process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string;
    const postHogHost: string = process.env.REACT_APP_PUBLIC_POSTHOG_HOST as string;
    // PostHog Init
    posthog.init(postHogKey, {
        api_host: postHogHost,
        autocapture: false,
        disable_session_recording: true,
        capture_pageview: false,
        persistence: "localStorage",
        loaded: () => posthog.identify(anonID),
    });

    useEffect(() => {
        if (query.ref) {
            dispatch(setReferralCode(query.ref.toString()));
        }

        if (utmParameters) {
            trackUtmParameters(utmParameters);
            dispatch(setUtmParameters(utmParameters));
        }
    }, [query.ref, utmParameters, dispatch]);

    // if auth token changes (e.g. after login or refresh), fetch user
    useEffect(() => {
        if (authToken) {
            dispatch(fetchUser());
        }
    }, [authToken, dispatch]);

    // if auth token is removed (e.g expired), refresh it
    useEffect(() => {
        if (!authToken && refreshToken) {
            dispatch(refreshAuthToken({ refreshToken: refreshToken }));
        }
    }, [authToken, refreshToken, dispatch]);

    // periodically refresh auth token
    useEffect(() => {
        if (refreshToken) {
            const timer = setInterval(() => {
                dispatch(refreshAuthToken({ refreshToken: refreshToken }));
            }, 1740000); // refresh every 29 mins (tokens expire in 1h)
            return () => clearInterval(timer);
        }
    }, [refreshToken, dispatch]);

    useEffect(() => {
        // bugfix for react-helmet document properties lag issue
        // see more at https://github.com/nfl/react-helmet/issues/189
        setTimeout(() => {
            // send page view on route change
            // TODO: page names coming from react router/helmet are not well named...
            analytics.page();
            // FOR backwards compatibility with OLD DATA going into Amplitude
            analyticsTrack("page.viewed", {
                path: reactRouterLocation.pathname,
                hash: reactRouterLocation.hash,
                search: reactRouterLocation.search,
                release: process.env.REACT_APP_VERSION,
            });
        }, 0);
    }, [reactRouterLocation]);

    useEffect(() => {
        const fingerprints: string[] = [];
        document.querySelectorAll("script[src$='.chunk.js']").forEach((script) => {
            const src = script.getAttribute("src");
            const match = src?.match(/(\w+).chunk.js/);
            if (match && match[1]) {
                fingerprints.push(match[1]);
            }
        });

        let sessionId = sessionStorage.getItem("sessionId");
        if (!sessionId) {
            sessionId = generateId();
            sessionStorage.setItem("sessionId", sessionId);
        }

        analyticsTrack("site.loaded", {
            jsFingerprints: fingerprints.join("-"),
            sessionId,
            release: process.env.REACT_APP_VERSION,
            host: window.location.hostname,
            path: reactRouterLocation.pathname,
            hash: reactRouterLocation.hash,
            search: reactRouterLocation.search,
            hasToken: Boolean(authToken || refreshToken),
            userAgent: navigator?.userAgent,
            isBrowserTest: isBrowser(),
        });
    }, []);

    // To simulate the UI for a regular member as an admin, set the query param "simulateMember"
    useEffect(() => {
        if (query.simulateMember) {
            dispatch(setSimulateMember(true));
        }
    }, []);

    // Detect events that might indicate that the user is inactive (and our requests may not go through)
    useEffect(() => {
        const events = ["online", "offline", "freeze", "resume"].map((name) => ({
            name,
            handler: () => analyticsTrack(`window.${name}`),
        }));
        events.forEach((e) => window.addEventListener(e.name, e.handler));

        return () => {
            events.forEach((e) => window.removeEventListener(e.name, e.handler));
        };
    }, []);

    return (
        <div className="App">
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider>
                        <CssBaseline />
                        <Sentry.ErrorBoundary fallback={<GenericErrorPage />}>
                            <PostHogProvider client={posthog}>
                                <MainContainer />
                            </PostHogProvider>
                        </Sentry.ErrorBoundary>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
};

export default connect()(App);
