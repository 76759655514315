import { Box, Button, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import { NAVBAR_HEIGHT } from "app/styles";
import { theme } from "app/theme";
import { RESULT_SECTION_WIDTH } from "./ResultsSection";

interface QuestionHeaderBarProps {
    question: string;
    onBackClick: () => void;
}

export const QuestionHeaderBar = (props: QuestionHeaderBarProps) => {
    const { question, onBackClick } = props;

    const isSmallWindow = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <Box
            sx={{
                pt: 1.5,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#F7F9F6",
            }}
        >
            <Button
                variant="tertiary"
                sx={{
                    position: "absolute",
                    left: isSmallWindow ? 5 : 20,
                    top: NAVBAR_HEIGHT + 29,
                    color: theme.palette.grey800,
                    textDecoration: "none",
                    ":hover": {
                        textDecoration: "none",
                    },
                }}
                onClick={onBackClick}
            >
                <span className="material-symbols-rounded">chevron_left</span>
                {!isSmallWindow && "Back"}
            </Button>
            <TextField
                value={question.replace(/\n+/g, " ")}
                fullWidth
                disabled
                size="small"
                sx={{
                    "& .MuiOutlinedInput-root": {
                        borderRadius: 0.5,
                        mb: 1.5,
                    },
                    maxWidth: RESULT_SECTION_WIDTH,
                    px: {
                        xs: 4.5,
                        lg: 0,
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" sx={{ mr: 2 }}>
                            <span className="material-symbols-rounded">help</span>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};
