import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Typography,
} from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import { theme } from "app/theme";
import { MarkdownRenderer } from "./MarkdownRenderer";
import { ExpandMore } from "@mui/icons-material";
import { User } from "@app/shared/types";

interface PostQuestionSectionProps {
    question: string;
    user: User;
    aiQuestionAnalysis: string | null;
    isAnalyzingQuestion: boolean;
    setReviewDrawerOpen: (open: boolean) => void;
}

export const PostQuestionSection = (props: PostQuestionSectionProps) => {
    const { question, user, aiQuestionAnalysis, isAnalyzingQuestion, setReviewDrawerOpen } = props;

    return (
        <Box>
            <Box sx={{ textAlign: "center", mt: 4 }}>
                <Typography variant="h5">Want a teacher to answer your question?</Typography>
                <Typography variant="body1">
                    If your question is different and you would like a better answer, you can post
                    your question in the lounge and a teacher will answer.
                </Typography>
            </Box>
            <Box
                sx={{
                    backgroundColor: theme.palette.neutralGold,
                    borderRadius: `${theme.borderRadius.small}px`,
                    p: 2,
                    mt: 2,
                    mb: 1,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
                    <Avatar
                        src={user.profile.picture || undefined}
                        sx={{ width: 18, height: 18 }}
                    />
                    <Typography variant="caption" sx={{ mb: 0 }}>
                        By {user.profile.fullName}
                    </Typography>
                </Box>

                <Typography variant="subtitle2" sx={{ fontWeight: 700, whiteSpace: "pre-line" }}>
                    {question}
                </Typography>
                <Button
                    variant="primary"
                    size="small"
                    onClick={() => {
                        analyticsTrack("question.preview.clicked");
                        setReviewDrawerOpen(true);
                    }}
                >
                    Review post
                </Button>
            </Box>
            {aiQuestionAnalysis && !isAnalyzingQuestion && (
                <Box
                    sx={{
                        mt: 0,
                        backgroundColor: theme.palette.neutralWarm,
                        borderRadius: `${theme.borderRadius.small}px`,
                        p: 2,
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                    }}
                >
                    <span
                        className="material-symbols-rounded"
                        style={{
                            color: `${theme.palette.accentEarthy}`,
                            fontSize: `${theme.typography.pxToRem(32)}`,
                        }}
                    >
                        lightbulb_2
                    </span>
                    <Typography variant="caption" sx={{ mb: 0 }}>
                        <MarkdownRenderer markdown={aiQuestionAnalysis} />
                    </Typography>
                </Box>
            )}
            <Accordion
                sx={{
                    backgroundColor: theme.palette.grey100,
                    borderRadius: `${theme.borderRadius.small}px !important`,
                    ":before": {
                        display: "none",
                    },
                    ":after": {
                        display: "none",
                    },
                }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="body1" sx={{ fontSize: "18px !important" }}>
                        <strong>💡 How you ask your question matters</strong>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: "left", px: 6, pt: 1 }}>
                    <Typography variant="body1">
                        The more specific you are with your question, the better a teacher can
                        personalise an answer for you.
                    </Typography>
                    <Typography variant="body1">
                        <ul style={{ paddingLeft: "1.5rem" }}>
                            <li>
                                <strong>Vulnerability:</strong> Could you share more of yourself by
                                describing a specific event or circumstance, or by expressing
                                relevant feelings, thoughts, or beliefs?
                            </li>
                            <li>
                                <strong>Clarity:</strong> Could you add or subtract any details to
                                make your question clearer and more specific?
                            </li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
