import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Chip, Divider, Typography, useMediaQuery } from "@mui/material";
import {
    GRAPHQL_MUTATION_ADD_USER_TO_PEER_GROUP,
    GRAPHQL_QUERY_ALL_PEER_GROUPS_FOR_COURSE,
    GRAPHQL_QUERY_COURSE,
} from "app/queries";
import { routes } from "app/routes";
import { BackArrowButton } from "components/BackArrowButton";
import PageWrapper from "components/PageWrapper";
import LoadingPage from "features/pages/LoadingPage";
import { Redirect, useHistory, useParams } from "react-router";
import { convertAndFormatPeerGroupTimeSlotToUserTimeZone } from "./courseHelpers";
import { theme } from "app/theme";
import { DateTime } from "luxon";
import { Course, PeerGroup, PeerGroupStatus, PeerGroupTimeSlot } from "@app/shared/types";
import { useSnackbar } from "components/SnackbarContext";
import { useQueryParams } from "hooks/useQueryParams";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/auth";
import { CSDialog } from "components/CSDialog";
import { useState } from "react";

export const JoinExistingPeerGroupPage = () => {
    const { courseId } = useParams<{ courseId: string }>();
    const query = useQueryParams();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const user = useSelector(selectUser);

    const history = useHistory();
    const { showSnackbar } = useSnackbar();

    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [chosenPeerGroupId, setChosenPeerGroupId] = useState<string | null>(null);
    const [chosenTimeSlot, setChosenTimeSlot] = useState<PeerGroupTimeSlot | null>(null);

    const timezone = DateTime.local().zoneName;

    const { data: courseData, loading: courseLoading } = useQuery(GRAPHQL_QUERY_COURSE, {
        variables: {
            id: courseId,
        },
    });

    const { data: peerGroupData, loading: peerGroupsLoading } = useQuery(
        GRAPHQL_QUERY_ALL_PEER_GROUPS_FOR_COURSE,
        {
            variables: {
                courseId: courseId,
            },
        },
    );

    const [joinPeerGroupMutation] = useMutation(GRAPHQL_MUTATION_ADD_USER_TO_PEER_GROUP);

    if (courseLoading || peerGroupsLoading) {
        return <LoadingPage />;
    }

    const course: Course = courseData?.course;
    const peerGroups: PeerGroup[] = peerGroupData?.allPeerGroupsForCourse;
    const peerGroupsWithLessThanMaxParticipants = peerGroups
        .filter((peerGroup) => peerGroup.participantIds.length < 9)
        .sort((a, b) => a.peerGroupTimeSlot.weekday - b.peerGroupTimeSlot.weekday);

    const peerGroupIdFromSwitchingUser =
        user && peerGroups.find((peerGroup) => peerGroup.participantIds.includes(user.id))?.id;
    const userIsAlreadyInPeerGroup = !!peerGroupIdFromSwitchingUser;

    if (
        !course?.myCourseMembership ||
        !course?.peerGroupStatus ||
        course?.peerGroupStatus === PeerGroupStatus.DISABLED
    ) {
        showSnackbar("You don't have access to this page", "error");
        return <Redirect to={routes.coursesDashboard()} />;
    }

    if (userIsAlreadyInPeerGroup && query.allowSwitching !== "true") {
        showSnackbar("You are already in a peer group", "warning");
        return <Redirect to={routes.coursePeerGroup(courseId)} />;
    }

    const showSwitchGroupFlow = userIsAlreadyInPeerGroup && query.allowSwitching === "true";

    const handleJoinGroup = async (peerGroupId: string) => {
        const result = await joinPeerGroupMutation({
            variables: {
                peerGroupId: peerGroupId,
                courseId: courseId,
            },
        });
        if (result.data) {
            history.push(routes.coursePeerGroup(courseId));
            const snackbarMessage = `Successfully ${showSwitchGroupFlow ? "switched" : "joined"} group`;
            showSnackbar(snackbarMessage, "success");
        } else {
            showSnackbar("Failed to join group. Please contact support", "error");
        }
    };

    return (
        <PageWrapper removeMarginTop>
            <BackArrowButton route={routes.coursesDashboard()} />
            <Typography variant="h2" sx={{ mb: 0 }}>
                {showSwitchGroupFlow ? "Switch to another peer group" : "Join a Peer Group"}
            </Typography>
            <Typography variant="body1">
                {!showSwitchGroupFlow &&
                    `The peer groups for ${course.name} were already assigned. Either you didn't select
                any time slots or we could not assign you to a time slot of your choice. Therefore,
                please select one of the existing groups below.`}
            </Typography>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        xs: "repeat(auto-fill, minmax(300px, 1fr))",
                        sm: "repeat(auto-fill, minmax(400px, 1fr))",
                    },
                    gap: 2,
                }}
            >
                {peerGroupsWithLessThanMaxParticipants.map((peerGroup) => {
                    return (
                        <Box
                            key={peerGroup.chatRoomUuid}
                            sx={{
                                backgroundColor: theme.palette.neutralWarm,
                                borderRadius: 1,
                                p: 2,
                                mb: 2,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mb: 1,
                                    alignItems: "center",
                                    flexDirection: { xs: "column", sm: "row" },
                                    gap: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: {
                                            xs: "center",
                                            sm: "flex-start",
                                        },
                                    }}
                                >
                                    {peerGroupIdFromSwitchingUser === peerGroup.id && (
                                        <Chip label="Your current group" variant="qaSession" />
                                    )}
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            mb: 0,
                                            textAlign: {
                                                xs: "center",
                                                sm: "left",
                                            },
                                        }}
                                    >
                                        {convertAndFormatPeerGroupTimeSlotToUserTimeZone(
                                            peerGroup.peerGroupTimeSlot,
                                            timezone,
                                            true,
                                        )}
                                    </Typography>
                                </Box>
                                <Chip
                                    label={`${peerGroup.participantIds.length} members`}
                                    size="medium"
                                />
                            </Box>

                            <Divider sx={{ my: 1 }} />
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: {
                                        xs: "flex-start",
                                        sm: "flex-end",
                                    },
                                    justifyContent: "space-between",
                                    flexGrow: 1,
                                    flexDirection: { xs: "column", sm: "row" },
                                }}
                            >
                                <Box>
                                    {peerGroup.participants.map((participant) => (
                                        <Typography
                                            variant="body1"
                                            sx={{ mb: 0 }}
                                            key={participant.name}
                                        >
                                            {participant.name}
                                        </Typography>
                                    ))}
                                </Box>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setChosenPeerGroupId(peerGroup.id);
                                        setConfirmationDialogOpen(true);
                                        setChosenTimeSlot(peerGroup.peerGroupTimeSlot);
                                    }}
                                    disabled={peerGroupIdFromSwitchingUser === peerGroup.id}
                                    fullWidth={isMobile}
                                    sx={{ mt: 1 }}
                                >
                                    Join group
                                </Button>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
            <CSDialog
                open={confirmationDialogOpen}
                onClose={() => setConfirmationDialogOpen(false)}
            >
                <Typography variant="h3">Are you sure you want to join this group?</Typography>
                <Typography variant="body1">
                    Only confirm if you are sure that you can commit to this time slot:
                </Typography>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {convertAndFormatPeerGroupTimeSlotToUserTimeZone(
                        chosenTimeSlot,
                        timezone,
                        true,
                    )}
                </Typography>
                {showSwitchGroupFlow && (
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        If you switch to another group, please let your previous group know that you
                        left.
                    </Typography>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        variant="primary"
                        onClick={() => handleJoinGroup(chosenPeerGroupId!)}
                        fullWidth={isMobile}
                    >
                        Confirm
                    </Button>
                </Box>
            </CSDialog>
        </PageWrapper>
    );
};
