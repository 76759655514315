import { createContext, useContext, useState, ReactNode } from "react";
import { CustomSnackbar } from "../components/CustomSnackbar";

interface SnackbarContextValue {
    showSnackbar: (message: string, severity?: "success" | "error" | "warning" | "info") => void;
    hideSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextValue>({
    showSnackbar: () => {},
    hideSnackbar: () => {},
});

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState<"success" | "error" | "warning" | "info">("success");

    const showSnackbar = (
        msg: string,
        sev: "success" | "error" | "warning" | "info" = "success",
    ) => {
        setMessage(msg);
        setSeverity(sev);
        setOpen(true);
    };

    const hideSnackbar = () => setOpen(false);

    return (
        <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
            {children}
            <CustomSnackbar
                open={open}
                onClose={hideSnackbar}
                message={message}
                severity={severity}
            />
        </SnackbarContext.Provider>
    );
};
