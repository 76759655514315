import { IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from "app/styles";
import { theme, titleFont } from "app/theme";
import classNames from "classnames";
import clsx from "clsx";
import {
    selectCanAccessMemberZone,
    selectIsEnrolledInCourses,
    selectIsLoggedIn,
} from "features/auth/auth";
import { isUserInMentorshipSignupFlow } from "features/signup/signupHelpers";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { useDetectScroll } from "hooks/useDetectScroll";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { AccountMenu } from "./AccountMenu";
import { LinkButton } from "./LinkButton";
import { MenuLink } from "./MenuLink";
import { selectIsMenuOpen, toggleMenu } from "./navigationSlice";
import { LinkTag, MenuLinkInfo, useNavigationLinks } from "./useNavigationLinks";
import { analyticsTrack } from "app/analytics/track";

export const MENU_SHADOW_HEIGHT = 8;

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        color: theme.palette.grey900,
        listStyle: "none",
        padding: 0,
        margin: 0,
        textAlign: "right",

        display: "flex",
        gap: theme.spacing(4.25),
        height: NAVBAR_HEIGHT - MENU_SHADOW_HEIGHT,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        position: "relative",

        [theme.breakpoints.down("md")]: {
            height: NAVBAR_HEIGHT_MOBILE,
        },
    },

    secondaryList: {
        color: theme.palette.grey900,
        listStyle: "none",
        padding: 0,
        margin: 0,
        textAlign: "right",

        display: "flex",
        gap: theme.spacing(2),
        height: NAVBAR_HEIGHT - MENU_SHADOW_HEIGHT,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        position: "relative",

        [theme.breakpoints.down("md")]: {
            height: NAVBAR_HEIGHT_MOBILE,
        },
    },

    scrolledMenu: {
        "&:before": {
            opacity: 0,
        },
    },

    link: {
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.grey900,
        textTransform: "none",

        "&:hover": {
            color: theme.palette.accentEarthy,
        },
    },

    primaryLink: {
        ...theme.typography.body1,
        border: theme.border.default,
        padding: theme.spacing(1, 3),
        borderRadius: theme.borderRadius.default,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    },

    activeLink: {},

    webflowLink: {
        // custom font design to match the webflow design
        fontFamily: titleFont,
        color: theme.palette.primaryLeaves,
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600,
        marginLeft: -30,

        "&:hover": {
            color: theme.palette.grey700,
        },
    },
    menuIcon: {
        color: theme.palette.primaryBanyan,
        fontSize: theme.typography.pxToRem(45),
        "&:hover": {
            color: theme.palette.accentEarthy,
        },
    },
    addMarginRight: {
        marginRight: theme.spacing(4),
    },
}));

export const TopBarMenu = React.memo(function TopBarMenu(props: { className?: string }) {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isBanyanMember = useSelector(selectCanAccessMemberZone);
    const hasScrolled = useDetectScroll();
    const isCourseMember = useSelector(selectIsEnrolledInCourses);

    const currentLocation = useLocation();
    const isInSignupFlow =
        currentLocation.pathname === routes.allInterestGroups() ||
        currentLocation.pathname.startsWith("/mindful-friend-groups/group/");

    const userIsInMentorshipSignupFlow = isUserInMentorshipSignupFlow(currentLocation);

    const showCtaButton = !isLoggedIn && !isInSignupFlow && !userIsInMentorshipSignupFlow;

    const showMobileMenu = useBreakpointQuery("lg");

    const classes = useStyles();

    const links = useNavigationLinks();

    const navbarLinks = links.filter(
        (x) => !x.tags.includes(LinkTag.ACCOUNT_LINKS) && !x.tags.includes(LinkTag.SECONDARY_LINKS),
    );
    const accountMenuLinks = links.filter((x) => x.tags.includes(LinkTag.ACCOUNT_LINKS));
    const secondaryLinks = links.filter((x) => x.tags.includes(LinkTag.SECONDARY_LINKS));

    const isMenuOpen = useSelector(selectIsMenuOpen);

    const handleOpenMenu = (event: React.MouseEvent<any>) => {
        event.preventDefault();
        dispatch(toggleMenu());
    };

    const renderLink = (link: MenuLinkInfo) => {
        const className = classNames(classes.link, {
            [classes.primaryLink]: link.tags.includes(LinkTag.LOGIN),
        });
        return (
            <li key={link.name}>
                <MenuLink link={link} className={className} activeClassName={classes.activeLink} />
            </li>
        );
    };

    const renderMobileMenuIcon = () => {
        return (
            <Tooltip title="Show menu">
                <IconButton onClick={handleOpenMenu}>
                    <span className={classNames("material-symbols-rounded", classes.menuIcon)}>
                        {isMenuOpen ? "close" : "menu"}
                    </span>
                </IconButton>
            </Tooltip>
        );
    };

    const renderMenu = () => (
        <nav
            className={props.className}
            style={{
                display: "flex",
                gap: isBanyanMember || isCourseMember ? 0 : theme.spacing(13),
            }}
        >
            <ul
                className={clsx(classes.list, {
                    [classes.scrolledMenu]: hasScrolled,
                    [classes.addMarginRight]: !showCtaButton,
                })}
            >
                {navbarLinks.map(renderLink)}
            </ul>
            <ul
                className={clsx(classes.secondaryList, {
                    [classes.scrolledMenu]: hasScrolled,
                    [classes.addMarginRight]: !showCtaButton,
                })}
            >
                {secondaryLinks.map(renderLink)}
                {isLoggedIn && isBanyanMember && (
                    <LinkButton
                        variant="primary"
                        size="small"
                        sx={{ px: 2 }}
                        to={routes.askTeacherPage()}
                        onClick={() => analyticsTrack("ask.teacher.button.clicked")}
                    >
                        Ask us anything
                    </LinkButton>
                )}
                {showCtaButton && (
                    <>
                        <LinkButton
                            to={routes.login()}
                            variant="secondary"
                            size="medium"
                            sx={{ textTransform: "none !important", minWidth: "none !important" }}
                        >
                            Login
                        </LinkButton>
                        <LinkButton
                            to={routes.signup()}
                            variant="primary"
                            size="medium"
                            sx={{ textTransform: "none !important" }}
                        >
                            Try for free
                        </LinkButton>
                    </>
                )}
                {accountMenuLinks.length > 0 ? <AccountMenu links={accountMenuLinks} /> : null}
            </ul>
        </nav>
    );

    return (
        <div className={classes.root} {...props}>
            {showMobileMenu ? renderMobileMenuIcon() : renderMenu()}
        </div>
    );
});
