import { Snackbar, Alert, IconButton, Slide, SlideProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SlideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="up" />;
};

interface CustomSnackbarProps {
    open: boolean;
    onClose: () => void;
    message: string;
    icon?: React.ReactNode;
    severity?: "success" | "error" | "warning" | "info";
}

export const CustomSnackbar = (props: CustomSnackbarProps) => {
    const { open, onClose, message, icon, severity = "success" } = props;

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
            TransitionComponent={SlideTransition}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <Alert
                onClose={onClose}
                severity={severity}
                icon={icon}
                sx={{ width: "100%" }}
                action={
                    <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                {message}
            </Alert>
        </Snackbar>
    );
};
