import {
    Avatar,
    Box,
    Link,
    LinkProps,
    List,
    ListItem,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import { routes } from "app/routes";
import { push } from "connected-react-router";
import { logout } from "features/auth/auth";
import * as React from "react";
import { useDispatch } from "react-redux";
import { NavLink as RouterNavLink } from "react-router-dom";
import { LinkTag, MenuLinkInfo } from "./useNavigationLinks";
import { scrollToElement } from "components/scrollToElement";
import useLocalStorage from "@rehooks/local-storage";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { border } from "@cloudinary/url-gen/qualifiers/background";
import { BorderRight } from "@mui/icons-material";
import { theme } from "app/theme";
import { justify } from "@cloudinary/url-gen/qualifiers/textAlignment";
import { closeMenu } from "./navigationSlice";

export interface MenuLinkProps {
    link: MenuLinkInfo;
    className?: string;
    activeClassName?: string;
    isFullPageMenu?: boolean;
    onClick?: VoidFunction;
    onClickSubNav?: VoidFunction;
}

const useStyles = makeStyles((theme) => ({
    badge: {
        position: "relative",
        "&:after": {
            position: "absolute",
            right: -14,
            top: 0,
            minWidth: 10,
            minHeight: 10,
            lineHeight: 10,
            padding: 5,
            color: theme.palette.neutralWhite,
            backgroundColor: theme.palette.error500,
            borderRadius: theme.borderRadius.default,
            content: "attr(data-badge)",
            border: theme.border.default,
            borderColor: theme.palette.error500,
            ...theme.typography.caption,
            marginBottom: 0,
        },
    },
    fullPageMenuLink: {
        display: "flex",

        "&:hover": {
            color: theme.palette.accentEarthy,
        },
    },
    submenuIcon: {
        verticalAlign: "middle",
    },
    rotate: {
        transform: "rotate(180deg)",
        transition: "transform 0.3s ease",
    },
    mobileMenuListItem: {
        color: theme.palette.grey900,
        textTransform: "none",
        "&:hover": {
            color: theme.palette.accentEarthy,
            backgroundColor: theme.palette.neutralWarm,
            borderRadius: theme.borderRadius.small,
        },
    },
}));

export const MenuLink = (props: MenuLinkProps) => {
    const dispatch = useDispatch();
    const { link, className, activeClassName, onClick, isFullPageMenu } = props;
    const classes = useStyles();

    const menuButtonRef = React.useRef(null);

    const [hasSeenIndicator, setHasSeenIndicator] = useLocalStorage<boolean | undefined>(
        link.indicatorKey ?? "",
    );

    const [subNavOpen, setSubNavOpen] = React.useState(false);
    const hasSubNav = link.subNavLinks && link.subNavLinks.length > 0;

    const onClickLink = (event: React.MouseEvent) => {
        analyticsTrack("navbar.link.click", {
            name: link.name,
            fullPageMenu: Boolean(isFullPageMenu),
        });
        if (hasSubNav) {
            event.preventDefault();
            setSubNavOpen(!subNavOpen);
        }
        if (link.indicatorKey) {
            setHasSeenIndicator(true);
        }
        if (onClick && !hasSubNav) {
            onClick();
        }
    };

    const onClickSubNav = (linkClicked: MenuLinkInfo) => {
        analyticsTrack("navbar.link.click", {
            name: linkClicked.name,
            fullPageMenu: Boolean(isFullPageMenu),
            subNav: true,
            parent: link.name,
        });
        if (props.onClickSubNav) {
            props.onClickSubNav();
        }
        setSubNavOpen(false);
    };

    const scrollToPageElement = () => {
        const element = document.getElementById(link.to);
        if (element) {
            scrollToElement(element, { offset: -10 });
        }
    };

    const linkBaseProps: LinkProps = {
        underline: "none",
        className: classNames(className, {
            [classes.badge]: link.indicatorKey && !hasSeenIndicator,
            [classes.fullPageMenuLink]: isFullPageMenu,
            hasSubNav: hasSubNav,
        }),
        color: "textPrimary",
    };

    const renderLinkName = () => (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexGrow: 1,
            }}
        >
            {link.name}
            {hasSubNav && (
                <ExpandMoreRoundedIcon
                    className={classNames(classes.submenuIcon, { [classes.rotate]: subNavOpen })}
                    sx={{ fontSize: isFullPageMenu ? theme.typography.pxToRem(40) : undefined }}
                />
            )}
        </Box>
    );

    const renderLink = () => {
        if (link.tags.includes(LinkTag.LOGOUT)) {
            return (
                <Link
                    {...linkBaseProps}
                    data-testid={isFullPageMenu ? undefined : link.testId}
                    href="#"
                    ref={menuButtonRef}
                    onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        dispatch(logout());
                        dispatch(closeMenu());
                        dispatch(push(routes.memberZone()));
                        onClickLink(ev);
                    }}
                >
                    {renderLinkName()}
                </Link>
            );
        } else if (link.webflowLink) {
            return (
                <Link
                    {...linkBaseProps}
                    href={link.to}
                    // target="_blank"
                    ref={menuButtonRef}
                    data-testid={isFullPageMenu ? undefined : link.testId}
                    onClick={onClickLink}
                >
                    {renderLinkName()}
                </Link>
            );
        } else if (link.external) {
            return (
                <Link
                    {...linkBaseProps}
                    href={link.to}
                    target="_blank"
                    ref={menuButtonRef}
                    data-testid={isFullPageMenu ? undefined : link.testId}
                    onClick={onClickLink}
                >
                    {renderLinkName()}
                </Link>
            );
        } else if (link.isAnchorLink) {
            return (
                <Link
                    {...linkBaseProps}
                    href={`#${link.to}`}
                    data-testid={isFullPageMenu ? undefined : link.testId}
                    ref={menuButtonRef}
                    onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();

                        onClickLink(ev);
                        scrollToPageElement();
                    }}
                >
                    {renderLinkName()}
                </Link>
            );
        } else {
            return (
                <Link
                    {...linkBaseProps}
                    to={link.to}
                    data-testid={isFullPageMenu ? undefined : link.testId}
                    ref={menuButtonRef}
                    activeClassName={activeClassName}
                    component={RouterNavLink}
                    exact={true}
                    onClick={onClickLink}
                >
                    {renderLinkName()}
                </Link>
            );
        }
    };

    const subnavLinkProps = (subNavLink: MenuLinkInfo) => {
        let linkProps: any = {
            color: "textPrimary",
            "data-testid": isFullPageMenu ? undefined : subNavLink.testId,
            exact: true,
            onClick: () => onClickSubNav(subNavLink),
            children: subNavLink.name,
        };

        if (subNavLink.external) {
            linkProps = {
                ...linkProps,
                component: "a",
                href: subNavLink.to,
                target: "_blank",
            };
        } else if (subNavLink.webflowLink) {
            linkProps = {
                ...linkProps,
                component: "a",
                href: subNavLink.to,
            };
        } else {
            linkProps = {
                ...linkProps,
                component: RouterNavLink,
                to: subNavLink.to,
            };
        }
        return linkProps;
    };

    const renderSubNav = () => {
        if (!hasSubNav || !link.subNavLinks) {
            return null;
        }

        if (isFullPageMenu && subNavOpen) {
            return (
                <List>
                    {link.subNavLinks.map((subNavLink, index) => {
                        const listItemProps = {
                            className: classes.mobileMenuListItem,
                            sx: { mb: 0, pb: 0, pt: 0 },
                            ...subnavLinkProps(subNavLink),
                        };

                        if (subNavLink.tags.includes(LinkTag.LOGOUT)) {
                            listItemProps.onClick = (ev: React.MouseEvent) => {
                                ev.preventDefault();
                                dispatch(logout());
                                dispatch(closeMenu());
                                dispatch(push(routes.memberZone()));
                                onClickLink(ev);
                            };
                        }

                        return (
                            <ListItem key={index} {...listItemProps}>
                                <Typography variant="h5" sx={{ fontWeight: 600, my: 1.5 }}>
                                    {subNavLink.name}
                                </Typography>
                            </ListItem>
                        );
                    })}
                </List>
            );
        }

        return (
            <Menu
                data-testid="subnavigationMenu"
                anchorEl={menuButtonRef.current}
                open={subNavOpen}
                onClose={() => setSubNavOpen(false)}
            >
                {link.subNavLinks.map((subNavLink, index) => (
                    <MenuItem key={index} {...subnavLinkProps(subNavLink)}>
                        {subNavLink.name}
                    </MenuItem>
                ))}
            </Menu>
        );
    };

    return (
        <>
            {renderLink()}
            {renderSubNav()}
        </>
    );
};
