import { Sangha, UserProfile } from "@app/shared/types";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { alpha, IconButton, Modal, Slide, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { localImageSrc } from "app/images";
import { routes } from "app/routes";
import { theme } from "app/theme";
import { FloatingButton } from "components/FloatingButton";
import { push } from "connected-react-router";
import { LoggedInUserProfileContext } from "features/auth/AuthRoute";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { UserProfileHighlight } from "../profile/UserProfileHighlight";
import { getSanghaMemberProfiles } from "./getSanghaMemberProfiles";
import { MemberDialog } from "./MemberDialog";
import { SanghaSessionPanel } from "./SanghaSessionPanel";

const getBackgroundStyle = (width?: number) =>
    `linear-gradient(${alpha(theme.palette.grey900, 0.15)}, ${alpha(
        theme.palette.grey900,
        0.15,
    )}), url(${localImageSrc("bg2-min.jpg", width)})`;

const useStyles = makeStyles((theme) => ({
    modal: {},
    title: {
        textAlign: "center",
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(4),
    },
    subtitle: {
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    memberShadeContainer: {
        overflowY: "auto",
        position: "absolute",
        top: "5%",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.neutralWhite,
    },
    memberShade: {
        padding: theme.spacing(0, 4),
        paddingBottom: theme.spacing(12),
    },
    closeButton: {
        position: "fixed",
        zIndex: 1,
        left: "50%",
        top: "5%",
        transform: "translateX(-50%) translateY(-40%)",
        color: theme.palette.neutralWhite,
        backgroundColor: theme.palette.accentEarthy,
        padding: 0,
        "&:hover": {
            backgroundColor: theme.palette.accentEarthy,
        },
    },

    floatingButton: {
        right: "50%",
        transform: "translateX(50%)",
    },
    sanghaDetails: {
        textAlign: "center",
        padding: theme.spacing(4, 2),
        margin: theme.spacing(4, 0, 4, 0),
        backgroundColor: theme.palette.primaryLeaves,

        "& .MuiLink-root": {
            color: theme.palette.neutralWarm,
        },

        "& .MuiTypography-colorTextSecondary": {
            color: alpha(theme.palette.neutralWarm, 0.5),
        },
    },
}));

export const SanghaMembers = (props: {
    sangha: Sangha;
    hasReflectionQuestion: boolean;
    open: boolean;
    onClose: VoidFunction;
    onUpdateMember: VoidFunction;
}) => {
    const [dialogMember, setDialogMember] = useState<UserProfile | null>(null);
    const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);

    const dispatch = useDispatch();

    const { sangha, open, onClose, onUpdateMember, hasReflectionQuestion } = props;

    const isMobile = useBreakpointQuery("md");

    const classes = useStyles();

    const user = useContext(LoggedInUserProfileContext);

    const profiles = getSanghaMemberProfiles(sangha);

    return (
        <Modal open={open} className={classes.modal} onClose={onClose}>
            <Slide in={open} direction="up">
                <div className={classes.memberShadeContainer} data-testid="sanghaMembersShade">
                    <FloatingButton
                        onClick={onClose}
                        className={classes.floatingButton}
                        variant="primary"
                    >
                        {isMobile ? "See message board" : "Go to my group"}
                    </FloatingButton>

                    <Tooltip title="Close members list">
                        <IconButton
                            classes={{ root: classes.closeButton }}
                            onClick={onClose}
                            data-testid="hideSanghaMembersButton"
                        >
                            <ExpandMoreRoundedIcon />
                        </IconButton>
                    </Tooltip>

                    <Typography variant="h2" className={classes.title}>
                        {sangha.name}
                    </Typography>

                    {isMobile ? (
                        <SanghaSessionPanel
                            sangha={sangha}
                            hasReflectionQuestion={hasReflectionQuestion}
                            classes={{ root: classes.sanghaDetails }}
                        />
                    ) : (
                        <Typography variant="h4" className={classes.subtitle}>
                            Your fellow peers
                        </Typography>
                    )}

                    <div className={classes.memberShade}>
                        {dialogMember && (
                            <MemberDialog
                                member={dialogMember}
                                open={dialogMember !== null}
                                onClose={() => setDialogMember(null)}
                            />
                        )}

                        <UserProfileHighlight
                            profile={{
                                ...sangha.teacher,
                                nickName: sangha.teacher.name,
                                bio: sangha.teacher.background,
                                isTeacher: true,
                            }}
                            onClick={() => {
                                dispatch(push(routes.teacherPage(sangha.teacher.id)));
                                analyticsTrack("member.sangha.teacher_profile.opened", {
                                    sanghaId: sangha.id,
                                    teacherName: sangha.teacher.name,
                                });
                            }}
                        />

                        {profiles.map((profile: UserProfile, i: number) => (
                            <UserProfileHighlight
                                key={profile.id}
                                profile={profile}
                                onClick={() => {
                                    setDialogMember(profile);
                                    analyticsTrack("member.sangha.member_profile.opened", {
                                        sanghaId: sangha.id,
                                    });
                                }}
                            />
                        ))}
                    </div>
                </div>
            </Slide>
        </Modal>
    );
};
