import { Box, Typography } from "@mui/material";
import { CheckCircle } from "./CheckCircle";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router";
import { useCallback, useState } from "react";
import React from "react";

export interface OnboardingItemProps {
    completed: boolean;
    title: string;
    link: string;
    additionalContext?: React.ReactNode;
    customActionButton?: (props: {
        isMenuOpen: boolean;
        setIsMenuOpen: (isOpen: boolean) => void;
    }) => React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    popupItem: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        gap: 16,
        padding: "12px 10px",
        borderRadius: theme.borderRadius.small,
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        "&:hover": {
            backgroundColor: theme.palette.grey100,

            "& $customActionButtons": {
                visibility: "visible",
            },
        },
    },
    popupItemMain: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
        flexGrow: 1,
        "&:hover": {
            color: "inherit",
        },
    },
    arrowLink: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.grey800,
        textDecoration: "none",
        padding: theme.spacing(1.5),
        "&:hover": {
            color: theme.palette.accentEarthy,
        },
    },
    onboardingLink: {
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
            color: "inherit",
        },
    },
    customActionButtons: {
        visibility: "hidden",
        display: "flex",
    },
}));

export const OnboardingItem = (props: OnboardingItemProps) => {
    const { completed, title, link, additionalContext, customActionButton } = props;
    const classes = useStyles();

    const locationHistory = useHistory();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleItemClick = useCallback(
        (_event: React.MouseEvent) => {
            if (!isMenuOpen) {
                if (link.startsWith("http")) {
                    window.open(link, "_blank");
                } else {
                    locationHistory.push(link);
                }
            }
        },
        [isMenuOpen, locationHistory, link],
    );

    return (
        <div className={classes.popupItem} onClick={handleItemClick}>
            <CheckCircle checked={completed} />

            <div className={classes.popupItemMain}>
                <Typography variant="h6">{title}</Typography>
                {additionalContext}
            </div>

            <Box className={classes.customActionButtons}>
                {customActionButton && customActionButton({ isMenuOpen, setIsMenuOpen })}
                <div className={classes.arrowLink}>
                    <span className={classNames("material-symbols-rounded")}>chevron_right</span>
                </div>
            </Box>
        </div>
    );
};
