import { Sangha, Session } from "@app/shared/types";
import { DateTime } from "luxon";

const formatTime = (time: DateTime, timezone: string) => {
    let formatted = time.setZone(timezone).toFormat("h:mm");
    // Remove ':00' if minutes are zero
    formatted = formatted.replace(/:00$/, "");
    return formatted;
};

const getPeriod = (time: DateTime, timezone: string) => {
    return time.setZone(timezone).toFormat("a").toLowerCase();
};

export const getShortTimeString = (
    startTime: DateTime,
    endTime: DateTime,
    timezone: string,
): string => {
    const startFormatted = formatTime(startTime, timezone);
    const endFormatted = formatTime(endTime, timezone);
    const startPeriod = getPeriod(startTime, timezone);
    const endPeriod = getPeriod(endTime, timezone);

    const showStartPeriod = startPeriod !== endPeriod;
    return showStartPeriod
        ? `${startFormatted} ${startPeriod}–${endFormatted} ${endPeriod}`
        : `${startFormatted}–${endFormatted} ${endPeriod}`;
};

export const isSanghaFromSessionFrozen = (session: Session, frozenGroupIds?: String[]) => {
    return (
        session.sangha &&
        frozenGroupIds &&
        frozenGroupIds.includes(session.sangha.id) &&
        !!session.sangha.firstAvailableSessionDate &&
        DateTime.fromISO(session.time) <
            DateTime.fromISO(session.sangha.firstAvailableSessionDate).minus({ hours: 1 }) // Accounting for DST
    );
};

const areSessionsAtSameTimeConsideringDST = (dateMillis1: number, dateMillis2: number): boolean => {
    const oneHour = 60 * 60 * 1000; // one hour in milliseconds
    return Math.abs(dateMillis1 - dateMillis2) <= oneHour;
};

export const separateGroups = (allGroups: Sangha[]) => {
    const { availableGroups, frozenGroups } = allGroups.reduce(
        (grouped, currentGroup) => {
            if (currentGroup.nextSession && currentGroup.firstAvailableSessionDate) {
                const nextSessionDate = DateTime.fromISO(currentGroup.nextSession.time).toMillis();
                const nextAvailableSessionDate = DateTime.fromISO(
                    currentGroup.firstAvailableSessionDate,
                ).toMillis();

                if (
                    areSessionsAtSameTimeConsideringDST(nextSessionDate, nextAvailableSessionDate)
                ) {
                    grouped.availableGroups.push(currentGroup);
                } else {
                    grouped.frozenGroups.push(currentGroup);
                }
            }
            return grouped;
        },
        { availableGroups: [] as Sangha[], frozenGroups: [] as Sangha[] },
    );

    return {
        availableGroups: availableGroups.sort((a, b) => b.openSpots - a.openSpots),
        frozenGroups: frozenGroups.sort((a, b) => b.openSpots - a.openSpots),
    };
};
