import { useQuery } from "@apollo/client";
import { Sangha, Session } from "@app/shared/types";
import {
    GRAPHQL_QUERY_AVAILABLE_IMMERSION_GROUPS,
    GRAPHQL_QUERY_AVAILABLE_IMMERSION_GROUPS_PUBLIC,
} from "app/queries";
import { routes } from "app/routes";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import PageWrapper from "components/PageWrapper";
import { selectCanAccessMemberZone, selectIsLoggedIn } from "features/auth/auth";
import LoadingPage from "features/pages/LoadingPage";
import { usePreferredGroup } from "hooks/usePreferredGroup";
import { useUserTimezone } from "hooks/useUserTimezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { useQueryParams } from "use-query-params";
import { CustomArrayParam } from "../filters/customQueryParamDefinitions";
import { filterSanghas } from "../filters/filterHelpers";
import { MentorshipGroupList } from "./MentorshipGroupList";

interface AddOnMentorshipGroupPageProps {
    sessions: Session[];
}

export const AddOnMentorshipGroupPage = (props: AddOnMentorshipGroupPageProps) => {
    const { sessions } = props;

    const isLoggedIn = useSelector(selectIsLoggedIn);
    const timezone = useUserTimezone();

    const canAccessMemberZone = useSelector(selectCanAccessMemberZone);
    const currentLocation = useLocation();

    const [query, setQuery] = useQueryParams({
        hideLockedGroups: CustomArrayParam,
        timeOfDay: CustomArrayParam,
        dayOfWeek: CustomArrayParam,
        teacher: CustomArrayParam,
        audience: CustomArrayParam,
    });

    const preferredGroupId = usePreferredGroup();

    const [immersionGroupsQuery, setImmersionGroupsQuery] = useState(
        isLoggedIn
            ? GRAPHQL_QUERY_AVAILABLE_IMMERSION_GROUPS
            : GRAPHQL_QUERY_AVAILABLE_IMMERSION_GROUPS_PUBLIC,
    );

    useEffect(() => {
        setImmersionGroupsQuery(
            isLoggedIn
                ? GRAPHQL_QUERY_AVAILABLE_IMMERSION_GROUPS
                : GRAPHQL_QUERY_AVAILABLE_IMMERSION_GROUPS_PUBLIC,
        );
    }, [isLoggedIn]);

    const {
        data: groupData,
        loading: groupsLoading,
        error: groupsError,
    } = useQuery(immersionGroupsQuery);

    if (currentLocation.pathname === routes.publicMentorshipGroups() && canAccessMemberZone) {
        return <Redirect to={routes.memberSanghas()} />;
    }

    if (groupsError) {
        return <GenericErrorMessage />;
    }

    let availableGroups: Sangha[] | undefined = groupData?.availableImmersionGroups;

    if (groupsLoading || !availableGroups) {
        return <LoadingPage />;
    }

    availableGroups = availableGroups
        .filter((x) => x.nextSession)
        .sort((a, b) => b.openSpots - a.openSpots);

    // List preferred group on top
    const preferredGroups = availableGroups.filter((x) => x.id === preferredGroupId);
    if (preferredGroups.length > 0) {
        availableGroups = [
            ...preferredGroups,
            ...availableGroups.filter((x) => x.id !== preferredGroupId),
        ];
    }

    const filteredSanghas = availableGroups
        ? filterSanghas(availableGroups, query, timezone)
        : undefined;

    return (
        <PageWrapper smallPaddingTop>
            <MentorshipGroupList
                groups={filteredSanghas || availableGroups}
                sessions={sessions}
                hideLockedGroups={query.hideLockedGroups.includes("true")}
            />
        </PageWrapper>
    );
};
