import { useQuery } from "@apollo/client";
import { ALL_ONBOARDING_ITEMS, STARTING_SOON_MINUTES } from "@app/shared/constants";
import { SanghaMembership, Session, SessionType } from "@app/shared/types";
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_QUERY_LATEST_INTENTION,
    GRAPHQL_QUERY_MENTORSHIP_GROUP_SANGHA_MEMBERSHIP,
    GRAPHQL_QUERY_MY_RECOMMENDATIONS,
    GRAPHQL_QUERY_MY_SCHEDULE,
    GRAPHQL_QUERY_UPCOMING_EVENTS,
} from "app/queries";
import { routes } from "app/routes";
import { theme } from "app/theme";
import classNames from "classnames";
import { AnnouncementsCarousel } from "components/AnnouncementsCarousel";
import { CSDialog } from "components/CSDialog";
import CustomPathForYou from "components/CustomPathForYou";
import DocumentContext from "components/DocumentContext";
import { GenericErrorPage } from "components/GenericErrorPage";
import PageWrapper from "components/PageWrapper";
import { selectHasLegacyCoreMembershipFeatures } from "features/auth/auth";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { LinkButton } from "features/navigation/LinkButton";
import LoadingPage from "features/pages/LoadingPage";
import { useCurrentServerTime } from "hooks/useCurrentServerTime";
import { useQueryParams } from "hooks/useQueryParams";
import _ from "lodash";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { EventListLoader } from "../eventDisplay/EventListLoader";
import {
    getWelcomePopupContent,
    shouldShowFirstThingsFirstSection,
} from "../mySanghas/mentorshipHelpers";
import { getCompletedOnboardingItems } from "../onboarding/onboardingHelpers";
import { OnboardingPopupButton } from "../onboarding/OnboardingPopupButton";
import { FirstThingsFirstSection } from "./FirstThingsFirstSection";
import { QuestionBox } from "./QuestionBox";

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        marginTop: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
    },
    colorGrey800: {
        color: theme.palette.grey800,
    },
    myScheduleHeader: {
        color: theme.palette.grey800,
        marginTop: theme.spacing(4),
    },
    headerWithIconContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    headerIcon: {
        color: theme.palette.warning500,
        fontSize: theme.typography.pxToRem(16),
    },
    emptyStateCard: {
        backgroundColor: theme.palette.grey100,
        borderRadius: theme.borderRadius.default,
        textAlign: "center",
        padding: theme.spacing(4, 7),
        [theme.breakpoints.up("sm")]: {
            width: "350px",
        },
        [theme.breakpoints.up("md")]: {
            width: "calc(100% / 2)",
        },
        [theme.breakpoints.up("lg")]: {
            width: "calc(100% / 3)",
        },
    },
    buttonContainer: {
        textAlign: "center",
    },
    noMargin: {
        margin: 0,
    },
    buttonIcon: {
        fontSize: theme.typography.pxToRem(26),
        marginRight: theme.spacing(1),
    },
    toggleButton: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    hidden: {
        display: "none",
    },
    visible: {
        display: "block",
    },
}));

enum SessionSections {
    MY_SCHEDULE = "MY_SCHEDULE",
    SESSIONS_RIGHT_NOW = "SESSIONS_RIGHT_NOW",
    RECOMMENDED_FOR_YOU = "RECOMMENDED_FOR_YOU",
}

export const MemberHomePage = () => {
    const classes = useStyles();

    const hasLegacyCoreMemebershipFeatures = useSelector(selectHasLegacyCoreMembershipFeatures);
    const [sessionsSectionDisplayed, setSessionsSectionDisplayed] = useState<SessionSections>(
        SessionSections.MY_SCHEDULE,
    );

    const query = useQueryParams();
    const [postSessionPopupVisibile, setPostSessionPopupVisibile] = useState(false);
    if (query.flow === "post-session" && !postSessionPopupVisibile) {
        setPostSessionPopupVisibile(true);
        analyticsTrack("post-session-ask-teacher-popup-shown");
    }

    const history = useHistory();

    const [mentorshipWelcomePopupOpen, setMentorshipWelcomePopupOpen] = useState(false);

    const handlePopupClose = () => {
        setMentorshipWelcomePopupOpen(false);
        localStorage.removeItem("mentorshipGroupSignupFlow");
        localStorage.removeItem("mentorshipGroupSignupGroupId");
    };

    const mentorshipGroupSignupFlow = localStorage.getItem("mentorshipGroupSignupFlow");
    const joinedMentorshipGroupSanghaId = localStorage.getItem("mentorshipGroupSignupGroupId");
    const { popupHeader, popupBody } = getWelcomePopupContent(mentorshipGroupSignupFlow);

    useEffect(() => {
        if (mentorshipGroupSignupFlow && joinedMentorshipGroupSanghaId) {
            setMentorshipWelcomePopupOpen(true);
        }
    }, [mentorshipGroupSignupFlow, joinedMentorshipGroupSanghaId]);

    // data to evaluate if the user has completed the intake survey and if we should show them the first things first section
    const {
        data: mentorshipGroupSanghaMembershipData,
        loading: mentorshipGroupSanghaMembershipLoading,
        error: mentorshipGroupSanghaMembershipError,
    } = useQuery(GRAPHQL_QUERY_MENTORSHIP_GROUP_SANGHA_MEMBERSHIP);
    const mentorshipGroupSanghaMembership =
        mentorshipGroupSanghaMembershipData?.mentorshipGroupSanghaMembership as SanghaMembership;
    const showFirstThingsFirstSection = shouldShowFirstThingsFirstSection(
        mentorshipGroupSanghaMembership,
    );

    const handleSectionSelection = (
        event: React.MouseEvent<HTMLElement>,
        newSection: SessionSections,
    ) => {
        if (newSection !== null) {
            setSessionsSectionDisplayed(newSection);
        }
    };

    const user = useContext(LoggedInUserProfileContext);
    const userNickName = user.profile.nickName;

    const currentServerTime = useCurrentServerTime();

    const {
        data: intentionData,
        loading: intentionLoading,
        error: intentionError,
    } = useQuery(GRAPHQL_QUERY_LATEST_INTENTION, {
        fetchPolicy: "network-only",
    });

    const {
        data: recommendationsData,
        loading: recommendationsLoading,
        error: recommendationsError,
    } = useQuery(GRAPHQL_QUERY_MY_RECOMMENDATIONS, {
        fetchPolicy: "network-only",
        skip: !intentionData?.latestIntention,
        variables: {
            customPathGoals: intentionData?.latestIntention?.recommendations,
        },
    });

    const [showAllEvents, setShowAllEvents] = useState(false);

    const handleSeeAllEventsClick = () => {
        setShowAllEvents(true);
    };

    const seeMoreButton = () => (
        <Box className={classes.buttonContainer}>
            <Button variant="secondary" onClick={handleSeeAllEventsClick}>
                See all
            </Button>
        </Box>
    );

    const [showOnboardingPopup, setShowOnboardingPopup] = useState(false);

    const completedItemsFromDatabase = [...(getCompletedOnboardingItems(user) || [])];

    const hasCompletedOnboarding = _.isEqual(
        completedItemsFromDatabase.sort(),
        ALL_ONBOARDING_ITEMS.sort(),
    );

    useEffect(() => {
        if (!hasCompletedOnboarding) {
            setShowOnboardingPopup(true);
        } else {
            analyticsTrack("onboarding.completed", {
                userId: user.id,
                onboardingItems: user.profile.completedOnboardingItems,
            });
        }
    }, [hasCompletedOnboarding]);

    if (intentionLoading || recommendationsLoading || mentorshipGroupSanghaMembershipLoading) {
        return <LoadingPage />;
    }

    if (intentionError || recommendationsError || mentorshipGroupSanghaMembershipError) {
        return <GenericErrorPage />;
    }

    // Filter out MFGs that started more than 10 minutes ago
    const filterSessionsRightNow = (event: Session) => {
        if (event.type !== SessionType.INTEREST_GROUP) {
            return true;
        }

        return DateTime.fromISO(event.time) > DateTime.now().minus({ minutes: 10 });
    };

    const sessionTypesForSessionsRightNow = [
        SessionType.COMMUNITY_SIT,
        SessionType.COMMUNITY_TALK,
        SessionType.SILENT_SIT,
        SessionType.INTEREST_GROUP,
        SessionType.TEACHER_ONLY_SESSION, // These are filtered out on the back-end for members
        SessionType.CONFLICT_RESOLUTION_PRACTICE,
        SessionType.QA_SESSION,
    ];

    return (
        <>
            {showFirstThingsFirstSection && <FirstThingsFirstSection />}

            <PageWrapper data-testid="memberHomePage" className={classes.pageWrapper}>
                <DocumentContext addBrandNameSuffix title="Home" />
                {!showFirstThingsFirstSection && (
                    <Typography variant="h2" sx={{ mb: 2 }}>
                        Welcome back, {userNickName}
                    </Typography>
                )}
                {showOnboardingPopup && hasLegacyCoreMemebershipFeatures && (
                    <OnboardingPopupButton />
                )}
                <QuestionBox />
                <ToggleButtonGroup
                    value={sessionsSectionDisplayed}
                    exclusive
                    onChange={handleSectionSelection}
                    color="primary"
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        "& .MuiToggleButtonGroup-grouped": {
                            borderRadius: `${theme.borderRadius.default}px !important`,
                        },
                        mb: 2,
                    }}
                >
                    <ToggleButton
                        value={SessionSections.MY_SCHEDULE}
                        aria-label="my schedule"
                        className={classes.toggleButton}
                    >
                        <span
                            className={classNames("material-symbols-rounded", classes.buttonIcon)}
                        >
                            calendar_today
                        </span>
                        My Schedule
                    </ToggleButton>
                    <ToggleButton
                        value={SessionSections.SESSIONS_RIGHT_NOW}
                        aria-label="sessions right now"
                        className={classes.toggleButton}
                        data-testid="sessionsRightNowButton"
                    >
                        <span
                            className={classNames("material-symbols-rounded", classes.buttonIcon)}
                        >
                            radio_button_checked
                        </span>
                        Starting soon
                    </ToggleButton>
                    <ToggleButton
                        value={SessionSections.RECOMMENDED_FOR_YOU}
                        aria-label="recommended for you"
                        className={classes.toggleButton}
                        data-testid="recommendedForYouButton"
                    >
                        <span
                            className={classNames("material-symbols-rounded", classes.buttonIcon)}
                        >
                            recommend
                        </span>
                        Recommended for you
                    </ToggleButton>
                </ToggleButtonGroup>
                <EventListLoader
                    className={classNames({
                        [classes.visible]: sessionsSectionDisplayed === SessionSections.MY_SCHEDULE,
                        [classes.hidden]: sessionsSectionDisplayed !== SessionSections.MY_SCHEDULE,
                    })}
                    elementAfter={seeMoreButton()}
                    eventsQuery={GRAPHQL_QUERY_MY_SCHEDULE}
                    data-testid="mySchedule"
                    currentServerTime={currentServerTime}
                    limitDisplayedEvents={3}
                    showAllEvents={showAllEvents}
                    showSpecificDate={true}
                    enableScrollFromQueryParams={true}
                    emptyStateElement={
                        <Box className={classes.emptyStateCard}>
                            <Typography variant="h6" className={classes.colorGrey800}>
                                Your schedule is empty
                            </Typography>
                            <LinkButton
                                to={routes.memberSessions()}
                                sx={{ marginTop: 1.5 }}
                                variant="primary"
                                size="small"
                                fullWidth={false}
                            >
                                Explore events
                            </LinkButton>
                        </Box>
                    }
                />
                <EventListLoader
                    className={classNames({
                        [classes.visible]:
                            sessionsSectionDisplayed === SessionSections.SESSIONS_RIGHT_NOW,
                        [classes.hidden]:
                            sessionsSectionDisplayed !== SessionSections.SESSIONS_RIGHT_NOW,
                    })}
                    sx={{ mb: 4 }}
                    data-testid="sessionsRightNow"
                    currentServerTime={currentServerTime}
                    eventsQuery={GRAPHQL_QUERY_UPCOMING_EVENTS}
                    queryOptions={{
                        variables: {
                            includeTypes: sessionTypesForSessionsRightNow,
                            startsWithinMinutes: STARTING_SOON_MINUTES,
                            limit: 6,
                        },
                    }}
                    filterEvents={filterSessionsRightNow}
                    emptyStateElement={
                        <Box className={classes.emptyStateCard}>
                            <Typography variant="h6" className={classes.colorGrey800}>
                                No events starting soon
                            </Typography>
                        </Box>
                    }
                    limitDisplayedEvents={3}
                />
            </PageWrapper>
            {hasLegacyCoreMemebershipFeatures && (
                <Box
                    className={classNames({
                        [classes.visible]:
                            sessionsSectionDisplayed === SessionSections.RECOMMENDED_FOR_YOU,
                        [classes.hidden]:
                            sessionsSectionDisplayed !== SessionSections.RECOMMENDED_FOR_YOU,
                    })}
                >
                    <CustomPathForYou
                        intention={intentionData.latestIntention}
                        recommendations={recommendationsData?.myRecommendations}
                    />
                </Box>
            )}
            {/* Commented out until we have something new to announce */}
            {/* <AnnouncementsCarousel
                intention={intentionData?.latestIntention}
                data-testid="announcementsCarousel"
            /> */}
            <CSDialog
                open={mentorshipWelcomePopupOpen}
                onClose={handlePopupClose}
                data-testid="mentorshipWelcomePopup"
            >
                <Typography variant="h3">{popupHeader}</Typography>
                <Typography variant="body1">{popupBody}</Typography>
                <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
                    <Button variant="secondary" onClick={handlePopupClose} size="small">
                        Skip for now
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            if (joinedMentorshipGroupSanghaId) {
                                history.push(
                                    routes.memberSanghaDetails(joinedMentorshipGroupSanghaId),
                                );
                            }
                            localStorage.removeItem("mentorshipGroupSignupFlow");
                            localStorage.removeItem("mentorshipGroupSignupGroupId");
                        }}
                        size="small"
                    >
                        Get started
                    </Button>
                </Box>
            </CSDialog>
            <CSDialog
                open={postSessionPopupVisibile}
                onClose={() => {
                    setPostSessionPopupVisibile(false);
                    history.push(routes.memberHomePage());
                }}
                sx={{ maxWidth: 800, margin: "auto" }}
            >
                <Typography variant="h3">ANY QUESTIONS ARISE DURING THIS SESSION?</Typography>
                <Typography variant="body1">
                    Ask any question about your practice or a life circumstance and a teacher will
                    answer it.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <LinkButton
                        variant="primary"
                        to={routes.askTeacherPage()}
                        onClick={() => analyticsTrack("post-session-ask-teacher-button-clicked")}
                    >
                        Ask a question
                    </LinkButton>
                </Box>
            </CSDialog>
        </>
    );
};
