import { useMutation } from "@apollo/client";
import { Video } from "@app/shared/types";
import { Avatar, Box, Button, Divider, TextField, Typography } from "@mui/material";
import { GRAPHQL_MUTATION_UPLOAD_POST_FROM_COMMENT_URL } from "app/queries";
import { routes } from "app/routes";
import { theme } from "app/theme";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import PageWrapper from "components/PageWrapper";
import { selectIsAdmin } from "features/auth/auth";
import { NavLink } from "features/navigation/NavLink";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

export const UploadVideoPage = () => {
    const isAdmin = useSelector(selectIsAdmin);

    const [commentUrl, setCommentUrl] = useState("");

    const [uploadResult, setUploadResult] = useState<Video | null>(null);

    const [uploadVideoMutation, { loading: videoIsUploading }] = useMutation(
        GRAPHQL_MUTATION_UPLOAD_POST_FROM_COMMENT_URL,
    );

    if (!isAdmin) {
        return <Redirect to={routes.memberHomePage()} />;
    }

    const handleUploadVideo = async () => {
        const result = await uploadVideoMutation({
            variables: {
                commentUrl: commentUrl,
            },
        });
        setUploadResult(result.data.uploadPostFromCommentUrl);
    };

    return (
        <PageWrapper>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 800,
                    alignItems: "center",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h2">Upload Post</Typography>
                <TextField
                    label="Comment URL"
                    value={commentUrl}
                    onChange={(e) => setCommentUrl(e.target.value)}
                    fullWidth
                />
                <Button
                    variant="primary"
                    onClick={handleUploadVideo}
                    fullWidth
                    disabled={videoIsUploading}
                >
                    Upload Post
                </Button>

                {videoIsUploading && (
                    <Box sx={{ mt: 4 }}>
                        <CircularProgressContainer />
                    </Box>
                )}

                {uploadResult && (
                    <Box
                        sx={{
                            borderRadius: 1.5,
                            mt: 2,
                            backgroundColor: theme.palette.neutralWarm,
                            p: 4,
                        }}
                    >
                        <Typography variant="h4" sx={{ mb: 2 }}>
                            Post uploaded successfully! 🎉
                        </Typography>

                        <Divider />

                        {uploadResult.originalQuestionMemberId && (
                            <Typography variant="body1">
                                <strong>Original question member id:</strong>
                                {uploadResult.originalQuestionMemberId}
                            </Typography>
                        )}
                        {uploadResult.originalQuestion && (
                            <Typography variant="body1">
                                <strong>Original question:</strong>
                                {uploadResult.originalQuestion}
                            </Typography>
                        )}
                        {uploadResult.originalQuestionSummary && (
                            <Typography variant="body1">
                                <strong>Original question summary:</strong>
                                {uploadResult.originalQuestionSummary}
                            </Typography>
                        )}

                        <Divider />

                        {uploadResult.teacherId && (
                            <Typography variant="body1">
                                <strong>Teacher id:</strong> {uploadResult.teacherId}
                            </Typography>
                        )}
                        {uploadResult.teacherAnswerText && (
                            <Typography variant="body1">
                                <strong>Teacher answer text:</strong>{" "}
                                {uploadResult.teacherAnswerText}
                            </Typography>
                        )}
                        <Typography variant="body1">
                            <strong>Teacher video transcription:</strong>{" "}
                            {uploadResult.transcription}
                        </Typography>
                        {uploadResult.transcriptionSummary && (
                            <Typography variant="body1">
                                <strong>Summary:</strong> {uploadResult.transcriptionSummary}
                            </Typography>
                        )}

                        <Divider />

                        {uploadResult.postUrl && (
                            <NavLink
                                to={uploadResult.postUrl}
                                openInNewTab
                                sx={{ display: "block" }}
                            >
                                Circle Post link
                            </NavLink>
                        )}
                        <NavLink to={`https://vimeo.com/${uploadResult.vimeoVideoId}`} openInNewTab>
                            Vimeo link
                        </NavLink>
                    </Box>
                )}
            </Box>
        </PageWrapper>
    );
};
