import { Grid2 as Grid, Typography, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { defaultFooterSections, FooterLink, FooterSection } from "./footerLinks";
import { NavLink } from "./NavLink";
import { Logo } from "components/Logo";
import { BRAND_NAME } from "@app/shared/constants";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { theme } from "app/theme";
import { EmailSubscribe } from "components/EmailSubscribe";

export const INSTAGRAM_LINK = "https://www.instagram.com/banyan__together";

export const YOUTUBE_LINK = "https://www.instagram.com/banyan__together";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primaryLeaves,
        color: theme.palette.neutralWhite,

        position: "relative",
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },

    link: {
        ...theme.typography.body1,
        color: theme.palette.neutralWhite,
        textDecoration: "none",
    },

    footerLinks: {
        marginBottom: 0,
    },

    container: {},

    footerLeft: {
        backgroundColor: theme.palette.primaryBanyan,
        width: "30%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    footerRight: {
        padding: theme.spacing(0, 8),
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(6),
        width: "100%",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingTop: theme.spacing(4),
        },
    },
    sectionHeaders: {
        color: theme.palette.neutralWhite,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(5),
    },
    gridContainer: {
        marginBottom: theme.spacing(6),
    },
    socialIcons: {
        marginBottom: theme.spacing(4),
    },
    socialIcon: {
        color: theme.palette.neutralWhite,
        marginRight: theme.spacing(2),
        "&:hover": {
            color: theme.palette.accentEarthy,
        },
        "& svg": {
            fontSize: 35,
        },
    },
    gridItem: {
        [theme.breakpoints.down("md")]: {
            marginRight: theme.spacing(2),
        },
    },
}));

export const AppFooter = (props: {
    dark?: boolean;
    hideNewsletter?: boolean;
    sections?: FooterSection[];
}) => {
    const classes = useStyles(props);
    const sections = props.sections || defaultFooterSections;

    const renderLink = (link: FooterLink) => {
        if (link.external) {
            return (
                <NavLink className={classes.link} to={link.to} target="_blank">
                    {link.name}
                </NavLink>
            );
        } else {
            return (
                <NavLink className={classes.link} to={link.to}>
                    {link.name}
                </NavLink>
            );
        }
    };

    const showMobileFooter = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div id="footer" className={classes.root}>
            <div className={classes.footerLeft}>
                <Logo variant="logo" margin="none" color="white" />
            </div>

            <div className={classes.footerRight}>
                <Typography variant="h3">Stay in touch</Typography>
                <EmailSubscribe placement="footer" />
                <Grid
                    container
                    className={classes.gridContainer}
                    direction={showMobileFooter ? "column" : "row"}
                >
                    {sections.map((section) => (
                        <Grid size={{ xs: 12, sm: 4 }} key={section.title} className={classes.gridItem}>
                            <Typography variant="h3" className={classes.sectionHeaders}>
                                {section.title}
                            </Typography>
                            {section.links.map((link) => (
                                <Typography
                                    variant="body2"
                                    key={link.name}
                                    className={classes.footerLinks}
                                >
                                    {renderLink(link)}
                                </Typography>
                            ))}
                        </Grid>
                    ))}
                </Grid>
                <div className={classes.socialIcons}>
                    <NavLink to={INSTAGRAM_LINK} target="_blank" className={classes.socialIcon}>
                        <InstagramIcon />
                    </NavLink>
                    <NavLink to={YOUTUBE_LINK} target="_blank" className={classes.socialIcon}>
                        <YouTubeIcon />
                    </NavLink>
                </div>
                <span>© {BRAND_NAME}, 2024</span>
            </div>
        </div>
    );
};
