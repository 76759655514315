import { useMutation, useQuery } from "@apollo/client";
import {
    Course,
    CourseMembership,
    PeerGroup,
    PeerGroupCycle,
    PeerGroupStatus,
    PeerGroupTimeSlot,
    HourMinute24h,
    PeerGroupTimeSlotInUserTimeZone,
} from "@app/shared/types";
import {
    Alert,
    Box,
    Button,
    Chip,
    Divider,
    IconButton,
    MenuItem,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {
    GRAPHQL_MUTATION_ADD_PEER_GROUP_TIMESLOT,
    GRAPHQL_MUTATION_DELETE_PEER_GROUP_TIMESLOT,
    GRAPHQL_QUERY_ALL_COURSE_MEMBERSHIPS_FOR_COURSE,
    GRAPHQL_QUERY_ALL_PEER_GROUPS_FOR_COURSE,
    GRAPHQL_QUERY_COURSE,
} from "app/queries";
import { routes } from "app/routes";
import { theme } from "app/theme";
import PageWrapper from "components/PageWrapper";
import { selectIsAdmin } from "features/auth/auth";
import LoadingPage from "features/pages/LoadingPage";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { WeekdayNumbers } from "luxon";
import { useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import {
    getDayFromWeekdayNumber,
    getPeerGroupTimeSlotId,
    formatPeerGroupTimeSlot,
    convertAndFormatPeerGroupTimeSlotToUserTimeZone,
    convertPeerGroupTimeSlotUserTimeZoneToUTC,
} from "./courseHelpers";
import { CSDialog } from "components/CSDialog";
import _ from "lodash";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
        </div>
    );
};

export const CoursePeerGroupAdminPage = () => {
    const { courseId } = useParams<{ courseId: string }>();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [tabValue, setTabValue] = useState<number>(0);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [timeSlotToDelete, setTimeSlotToDelete] = useState<PeerGroupTimeSlot | null>(null);

    const [newTimeSlotWeekday, setNewTimeSlotWeekday] = useState<WeekdayNumbers>(1);
    const [newTimeSlotStartTime, setNewTimeSlotStartTime] = useState<HourMinute24h>("00:00");
    const [newTimeSlotCycle, setNewTimeSlotCycle] = useState<PeerGroupCycle>("biweekly");

    const [newTimeSlotDialogOpen, setNewTimeSlotDialogOpen] = useState<boolean>(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const isAdmin = useSelector(selectIsAdmin);

    const timezone = "America/Los_Angeles";

    const { data: peerGroupData, loading: peerGroupsLoading } = useQuery(
        GRAPHQL_QUERY_ALL_PEER_GROUPS_FOR_COURSE,
        {
            variables: {
                courseId: courseId,
            },
        },
    );

    const { data: courseMembershipData, loading: courseMembershipLoading } = useQuery(
        GRAPHQL_QUERY_ALL_COURSE_MEMBERSHIPS_FOR_COURSE,
        {
            variables: {
                courseId: courseId,
            },
        },
    );

    const { data: courseData, loading: courseLoading } = useQuery(GRAPHQL_QUERY_COURSE, {
        variables: {
            id: courseId,
        },
    });

    const [deleteTimeSlotMutation] = useMutation(GRAPHQL_MUTATION_DELETE_PEER_GROUP_TIMESLOT, {
        refetchQueries: [
            {
                query: GRAPHQL_QUERY_COURSE,
                variables: {
                    id: courseId,
                },
            },
        ],
    });

    const [addNewTimeSlotMutation] = useMutation(GRAPHQL_MUTATION_ADD_PEER_GROUP_TIMESLOT, {
        refetchQueries: [
            {
                query: GRAPHQL_QUERY_COURSE,
                variables: {
                    id: courseId,
                },
            },
        ],
    });

    if (!isAdmin) {
        return <Redirect to={routes.coursesDashboard()} />;
    }

    if (peerGroupsLoading || courseLoading || courseMembershipLoading) {
        return <LoadingPage />;
    }

    const peerGroups: PeerGroup[] = peerGroupData?.allPeerGroupsForCourse;
    const courseMemberships: CourseMembership[] =
        courseMembershipData?.allCourseMembershipsForCourse;
    const course: Course = courseData?.course;

    const courseMembershipsWithoutTimeSlots = courseMemberships.filter(
        (courseMembership) =>
            !courseMembership.selectedPeerGroupTimeSlots ||
            courseMembership.selectedPeerGroupTimeSlots.length === 0,
    );

    const assignedMemberIds = new Set(peerGroups.flatMap((group) => group.participantIds));

    const unassignedMembers = courseMemberships.filter(
        (courseMembership) => !assignedMemberIds.has(courseMembership.userId),
    );

    return (
        <PageWrapper removeMarginTop>
            <Typography variant="h4" sx={{ mb: 0, textAlign: "center" }}>
                Peer Group Admin Dashbard - {course.name}
            </Typography>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Course Sections Tabs"
                variant={isMobile ? "scrollable" : "fullWidth"}
                scrollButtons="auto"
                textColor="primary"
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: theme.palette.accentEarthy,
                        height: 4,
                        borderRadius: 1,
                    },
                }}
                sx={{
                    backgroundColor: theme.palette.neutralCool,
                    boxShadow: 1,
                    borderRadius: 1,
                    mt: 2,
                }}
            >
                <Tab
                    label="Summary"
                    id="tab-0"
                    aria-controls="tabpanel-0"
                    sx={{
                        "&.Mui-selected": {
                            color: theme.palette.accentEarthy,
                        },
                    }}
                />
                <Tab
                    label="Peer Groups"
                    id="tab-1"
                    aria-controls="tabpanel-1"
                    sx={{
                        "&.Mui-selected": {
                            color: theme.palette.accentEarthy,
                        },
                    }}
                />
                <Tab
                    label="Unassigned"
                    id="tab-2"
                    aria-controls="tabpanel-2"
                    sx={{
                        "&.Mui-selected": {
                            color: theme.palette.accentEarthy,
                        },
                    }}
                />
                <Tab
                    label="Time slots"
                    id="tab-3"
                    aria-controls="tabpanel-3"
                    sx={{
                        "&.Mui-selected": {
                            color: theme.palette.accentEarthy,
                        },
                    }}
                />
            </Tabs>

            {/* SUMMARY BOX */}
            <TabPanel value={tabValue} index={0}>
                <Box sx={{ backgroundColor: theme.palette.neutralWarm, borderRadius: 1, p: 2 }}>
                    <Table sx={{ width: "100%", borderCollapse: "collapse" }}>
                        <TableBody>
                            {[
                                { label: "Total participants", value: courseMemberships.length },
                                { label: "Assigned participants", value: assignedMemberIds.size },
                                {
                                    label: "Unassigned participants",
                                    value: unassignedMembers.length,
                                },
                                {
                                    label: "Participants without time slots",
                                    value: courseMembershipsWithoutTimeSlots.length,
                                },
                                { label: "Peer group status", value: course.peerGroupStatus },
                                { label: "Peer groups", value: peerGroups.length },
                            ].map(({ label, value }, index) => (
                                <TableRow
                                    key={label}
                                    sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
                                >
                                    <TableCell
                                        sx={{
                                            borderTop: "1px solid",
                                            borderBottom: "1px solid",
                                            borderRight: "1px solid",
                                            borderColor: "grey.300",
                                            py: 1,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ mb: 0, fontWeight: 600 }}>
                                            {label}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderTop: "1px solid",
                                            borderBottom: "1px solid",
                                            borderLeft: "1px solid",
                                            borderColor: "grey.300",
                                            py: 1,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ mb: 0 }}>
                                            {value}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </TabPanel>

            {/* PEER GROUPS */}
            <TabPanel value={tabValue} index={1}>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "repeat(auto-fill, minmax(300px, 1fr))",
                            sm: "repeat(auto-fill, minmax(400px, 1fr))",
                        },
                        gap: 2,
                    }}
                >
                    {peerGroups.length > 0 ? (
                        peerGroups.map((peerGroup) => {
                            return (
                                <Box
                                    key={peerGroup.chatRoomUuid}
                                    sx={{
                                        backgroundColor: theme.palette.neutralWarm,
                                        borderRadius: 1,
                                        p: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            mb: 1,
                                            alignItems: "center",
                                            flexDirection: { xs: "column", sm: "row" },
                                            gap: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: 1,
                                                alignItems: { xs: "center", sm: "flex-start" },
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Chip
                                                label={peerGroup.id}
                                                sx={{
                                                    "& .MuiChip-label": {
                                                        textTransform: "none",
                                                    },
                                                }}
                                                size="small"
                                            />
                                            <Typography variant="h6" sx={{ mb: 0 }}>
                                                {formatPeerGroupTimeSlot(
                                                    peerGroup.peerGroupTimeSlot,
                                                    timezone,
                                                )}
                                            </Typography>
                                        </Box>
                                        <Chip
                                            label={`${peerGroup.participantIds.length} members`}
                                            size="medium"
                                            variant="green"
                                        />
                                    </Box>

                                    <Divider sx={{ my: 1 }} />
                                    {peerGroup.participants.map((participant) => (
                                        <Typography
                                            variant="body1"
                                            sx={{ mb: 0 }}
                                            key={participant.email}
                                        >
                                            {`${participant.name} - ${participant.email}`}
                                        </Typography>
                                    ))}
                                </Box>
                            );
                        })
                    ) : (
                        <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
                            No peer groups created yet
                        </Typography>
                    )}
                </Box>
            </TabPanel>

            {/* UNASSIGNED PARTICIPANTS */}
            <TabPanel value={tabValue} index={2}>
                <Box>
                    {unassignedMembers.map((courseMembership) => (
                        <Box
                            key={courseMembership.userId}
                            sx={{
                                backgroundColor: theme.palette.neutralWarm,
                                borderRadius: 1,
                                p: 2,
                                mb: 2,
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 0 }}>
                                {courseMembership.userProfile?.fullName} -{" "}
                                {courseMembership.userProfile?.email}
                            </Typography>
                            {courseMembership.selectedPeerGroupTimeSlots ? (
                                courseMembership.selectedPeerGroupTimeSlots.map((timeSlot) => (
                                    <Typography
                                        variant="body1"
                                        sx={{ mb: 0 }}
                                        key={getPeerGroupTimeSlotId(timeSlot)}
                                    >
                                        {formatPeerGroupTimeSlot(timeSlot, timezone)}
                                    </Typography>
                                ))
                            ) : (
                                <Typography variant="body1" sx={{ mb: 0 }}>
                                    No time slots chosen
                                </Typography>
                            )}
                        </Box>
                    ))}
                </Box>
            </TabPanel>

            {/* TIME SLOTS */}
            <TabPanel value={tabValue} index={3}>
                {course.peerGroupStatus === PeerGroupStatus.TIME_SLOT_SELECTION && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        Changing time slots while the peer group status is "TIME_SLOT_SELECTION" can
                        impact how groups are formed. Please make sure there can still be enough
                        participants per time slot after adding or deleting time slots.
                    </Alert>
                )}
                {course.peerGroupStatus === PeerGroupStatus.GROUPS_ASSIGNED && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        Changing time slots while the peer group status is "GROUPS_ASSIGNED" won't
                        have any impact, since groups are already formed.
                    </Alert>
                )}
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "repeat(auto-fill, minmax(300px, 1fr))",
                            sm: "repeat(auto-fill, minmax(400px, 1fr))",
                        },
                        gap: 2,
                    }}
                >
                    {course.availablePeerGroupTimeSlots &&
                        course.availablePeerGroupTimeSlots.map((timeSlot) => (
                            <Box
                                key={getPeerGroupTimeSlotId(timeSlot)}
                                sx={{
                                    backgroundColor: theme.palette.neutralWarm,
                                    borderRadius: 1,
                                    p: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h6" sx={{ mb: 0 }}>
                                    {convertAndFormatPeerGroupTimeSlotToUserTimeZone(
                                        timeSlot,
                                        timezone,
                                    )}
                                </Typography>
                                <Tooltip title="Delete time slot">
                                    <IconButton
                                        onClick={() => {
                                            setTimeSlotToDelete(timeSlot);
                                            setConfirmationDialogOpen(true);
                                        }}
                                        size="small"
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        ))}
                    <Box
                        sx={{
                            backgroundColor: theme.palette.grey150,
                            borderRadius: 1,
                            p: 2,
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            gap: 2,
                            fontWeight: 600,
                            fontSize: theme.typography.pxToRem(20),
                            "&:hover": {
                                backgroundColor: theme.palette.grey200,
                            },
                        }}
                        onClick={() => setNewTimeSlotDialogOpen(true)}
                    >
                        <Add sx={{ fontSize: theme.typography.pxToRem(35) }} /> Add time slot
                    </Box>
                </Box>
            </TabPanel>

            {/* DELETE TIME SLOT DIALOG */}
            <CSDialog
                open={confirmationDialogOpen}
                onClose={() => setConfirmationDialogOpen(false)}
            >
                <Typography variant="h3" sx={{ mb: 2 }}>
                    Are you sure you want to delete this time slot?
                </Typography>
                <Box
                    sx={{
                        backgroundColor: theme.palette.neutralWarm,
                        borderRadius: 1,
                        p: 2,
                        width: "fit-content",
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 0 }}>
                        {convertAndFormatPeerGroupTimeSlotToUserTimeZone(
                            timeSlotToDelete,
                            timezone,
                        )}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        variant="primary"
                        onClick={async () => {
                            await deleteTimeSlotMutation({
                                variables: {
                                    courseId,
                                    peerGroupTimeSlot: timeSlotToDelete,
                                },
                            });
                            setConfirmationDialogOpen(false);
                        }}
                    >
                        Delete
                    </Button>
                </Box>
            </CSDialog>
            {/* CREATE NEW TIME SLOT DIALOG */}
            <CSDialog open={newTimeSlotDialogOpen} onClose={() => setNewTimeSlotDialogOpen(false)}>
                <Typography variant="h3" sx={{ mb: 2 }}>
                    Add a new time slot
                </Typography>
                <TextField
                    label="Week day"
                    select
                    fullWidth
                    value={newTimeSlotWeekday}
                    onChange={(e) =>
                        setNewTimeSlotWeekday(e.target.value as unknown as WeekdayNumbers)
                    }
                    sx={{ mb: 1 }}
                >
                    {[1, 2, 3, 4, 5, 6, 7].map((weekday) => (
                        <MenuItem key={weekday} value={weekday}>
                            {getDayFromWeekdayNumber(weekday as WeekdayNumbers)}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Start time in 24h format in PDT"
                    type="time"
                    fullWidth
                    value={newTimeSlotStartTime}
                    onChange={(e) =>
                        setNewTimeSlotStartTime(e.target.value as unknown as HourMinute24h)
                    }
                    sx={{ mb: 1 }}
                />
                <TextField
                    label="Cycle"
                    select
                    fullWidth
                    value={newTimeSlotCycle}
                    onChange={(e) =>
                        setNewTimeSlotCycle(e.target.value as unknown as PeerGroupCycle)
                    }
                    sx={{ mb: 1 }}
                >
                    {["biweekly", "weekly"].map((cycle) => (
                        <MenuItem key={cycle} value={cycle}>
                            {_.capitalize(cycle)}
                        </MenuItem>
                    ))}
                </TextField>
                <Button
                    variant="primary"
                    onClick={async () => {
                        const timeSlotInUserTimeZone = {
                            weekday: newTimeSlotWeekday,
                            startTimeInUserTimeZone: newTimeSlotStartTime,
                            cycle: newTimeSlotCycle,
                        } as PeerGroupTimeSlotInUserTimeZone;

                        const timeSlotInUTC = convertPeerGroupTimeSlotUserTimeZoneToUTC(
                            timeSlotInUserTimeZone,
                            timezone,
                        );

                        await addNewTimeSlotMutation({
                            variables: {
                                courseId,
                                peerGroupTimeSlot: timeSlotInUTC,
                            },
                        });
                        setNewTimeSlotDialogOpen(false);
                    }}
                >
                    Confirm
                </Button>
            </CSDialog>
        </PageWrapper>
    );
};
