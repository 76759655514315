import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, useMediaQuery } from "@mui/material";
import { GRAPHQL_MUTATION_IMPROVE_QUESTION, GRAPHQL_QUERY_MATCHING_VIDEO } from "app/queries";
import { useContext, useEffect, useRef, useState } from "react";
import { theme } from "app/theme";
import { analyticsTrack } from "app/analytics/track";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import { Video } from "@app/shared/types";
import { ReviewPostDrawer } from "./ReviewPostDrawer";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { QandAHeroSection } from "./QandAHeroSection";
import { QuestionHeaderBar } from "./QuestionHeaderBar";
import { routes } from "app/routes";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "features/auth/auth";
import { Redirect } from "react-router";
import { ContactSupportSnackbar } from "./ContactSupportSnackbar";
import { ResultsSection } from "./ResultsSection";
import { GenericErrorPage } from "components/GenericErrorPage";
import { ExampleQuestionsSection } from "./ExampleQuestionsSection";

export const QandAPage = () => {
    const isAdmin = useSelector(selectIsAdmin);
    const user = useContext(LoggedInUserProfileContext);

    const [question, setQuestion] = useState("");
    const [reviewDrawerOpen, setReviewDrawerOpen] = useState(false);
    const [aiQuestionAnalysis, setAiQuestionAnalysis] = useState<string | null>(null);
    const [showResults, setShowResults] = useState(false);
    const [supportSnackbarOpen, setSupportSnackbarOpen] = useState(false);

    const isSupportQuestion = aiQuestionAnalysis?.toLowerCase() === "support";
    const isQuestionSufficient = aiQuestionAnalysis?.toLowerCase().startsWith("perfect");

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [
        analyzeQuestionWithAiMutation,
        { loading: isAnalyzingQuestion, error: improvementError },
    ] = useMutation(GRAPHQL_MUTATION_IMPROVE_QUESTION);

    const [answerVideo, setAnswerVideo] = useState<Video | null>();
    const previouslyQueriedQuestion = useRef<string | null>(null);

    const [fetchMatchingVideo, { data, loading: answerVideoLoading, error: videoMatchError }] =
        useLazyQuery(GRAPHQL_QUERY_MATCHING_VIDEO);

    useEffect(() => {
        if (data && answerVideo !== data.matchingVideo) {
            setAnswerVideo(data.matchingVideo);
        }
    }, [data]);

    const analyzeQuestionWithAi = async (passedQuestion?: string, previousContext?: string) => {
        if ((passedQuestion || question) !== previouslyQueriedQuestion.current) {
            const result = await analyzeQuestionWithAiMutation({
                variables: {
                    question: passedQuestion || question,
                    previousContext: previousContext,
                },
            });
            setAiQuestionAnalysis(result.data.analyzeQuestionWithAi);
            analyticsTrack("show.ai.question.guidance", {
                userQuestion: passedQuestion || question,
                aiQuestionAnalysis: result.data.analyzeQuestionWithAi,
            });
            previouslyQueriedQuestion.current = question;
        }
    };

    const handleFetchingVideo = async () => {
        setShowResults(true);
        if (question !== previouslyQueriedQuestion.current) {
            await fetchMatchingVideo({
                variables: { question },
            });
            await analyzeQuestionWithAi();
        }
    };

    useEffect(() => {
        if (isSupportQuestion) {
            analyticsTrack("asked.support.question");
            setReviewDrawerOpen(false);
            setShowResults(false);
            setSupportSnackbarOpen(true);
        } else if (isQuestionSufficient) {
            analyticsTrack("asked.good.question");
        }
    }, [isSupportQuestion, isQuestionSufficient]);

    if (!isAdmin) {
        return <Redirect to={routes.memberHomePage()} />;
    }

    if (improvementError || videoMatchError) {
        return <GenericErrorPage />;
    }

    return (
        <>
            {showResults ? (
                <>
                    <QuestionHeaderBar
                        question={question}
                        onBackClick={() => {
                            setShowResults(false);
                        }}
                    />
                    <ResultsSection
                        answerVideoLoading={answerVideoLoading}
                        question={question}
                        user={user}
                        aiQuestionAnalysis={aiQuestionAnalysis}
                        isAnalyzingQuestion={isAnalyzingQuestion}
                        setReviewDrawerOpen={setReviewDrawerOpen}
                        answerVideo={answerVideo}
                    />
                </>
            ) : (
                <>
                    <QandAHeroSection
                        question={question}
                        isMobile={isMobile}
                        setQuestion={setQuestion}
                        handleFetchingVideo={handleFetchingVideo}
                        loading={answerVideoLoading}
                    />
                    <ExampleQuestionsSection />
                </>
            )}

            <ReviewPostDrawer
                open={reviewDrawerOpen}
                onClose={() => setReviewDrawerOpen(false)}
                question={question}
                user={user}
                isAnalyzingQuestion={isAnalyzingQuestion}
                aiQuestionAnalysis={aiQuestionAnalysis}
                isMobile={isMobile}
                onSubmit={() => {
                    setQuestion("");
                    setShowResults(false);
                }}
                setQuestion={setQuestion}
                analyzeQuestionWithAi={analyzeQuestionWithAi}
            />

            <ContactSupportSnackbar
                supportSnackbarOpen={supportSnackbarOpen}
                setSupportSnackbarOpen={setSupportSnackbarOpen}
            />
        </>
    );
};
