import { Box, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import { PostQuestionSection } from "./PostQuestionSection";
import { VideoAnswerSection } from "./VideoAnswerSection";
import { User, Video } from "@app/shared/types";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import { theme } from "app/theme";

interface ResultsSectionProps {
    answerVideoLoading: boolean;
    question: string;
    user: User;
    aiQuestionAnalysis: string | null;
    isAnalyzingQuestion: boolean;
    setReviewDrawerOpen: (open: boolean) => void;
    answerVideo?: Video | null;
}

export const RESULT_SECTION_WIDTH = 1000;

export const ResultsSection = (props: ResultsSectionProps) => {
    const {
        answerVideo,
        answerVideoLoading,
        question,
        user,
        aiQuestionAnalysis,
        isAnalyzingQuestion,
        setReviewDrawerOpen,
    } = props;

    const renderResultsSection = () => {
        if (answerVideo === null) {
            return (
                <>
                    <Typography variant="h6" sx={{ mt: 4, textAlign: "center" }}>
                        Your question doesn't match any of our videos.
                    </Typography>
                    <PostQuestionSection
                        question={question}
                        user={user}
                        aiQuestionAnalysis={aiQuestionAnalysis}
                        isAnalyzingQuestion={isAnalyzingQuestion}
                        setReviewDrawerOpen={setReviewDrawerOpen}
                    />
                </>
            );
        } else if (answerVideo) {
            return (
                <Box
                    sx={{
                        borderRadius: 1.5,
                        mt: 1,
                        py: 2,
                        position: "relative",
                        maxWidth: RESULT_SECTION_WIDTH,
                        margin: "0 auto",
                    }}
                    id="answerBox"
                >
                    <VideoAnswerSection answerVideo={answerVideo} />

                    <PostQuestionSection
                        question={question}
                        user={user}
                        aiQuestionAnalysis={aiQuestionAnalysis}
                        isAnalyzingQuestion={isAnalyzingQuestion}
                        setReviewDrawerOpen={setReviewDrawerOpen}
                    />
                </Box>
            );
        }
    };
    return (
        <PageWrapper removeTopAndBottomMargins>
            {answerVideoLoading ? (
                <Box sx={{ mt: "10vh" }}>
                    <CircularProgressContainer
                        size={60}
                        sx={{ color: theme.palette.primaryLeaves }}
                    />
                </Box>
            ) : (
                renderResultsSection()
            )}
        </PageWrapper>
    );
};
