import { Box, Typography } from "@mui/material";
import { ExampleQuestionCard } from "./ExampleQuestionCard";
import _ from "lodash";
import { useMemo } from "react";

const EXAMPLE_QUESITON = [
    {
        memberName: "Munisha Tumato",
        question: `Hi community. I’m feeling such tremendous tension internally right now, and trying to figure out a few things. Hoping to hear your thoughts and experiences for guidance.

            I don’t know how to reconcile my intention to live peacefully on this planet with what’s happening in the world, in so many places, and the need for action. For me to experience peace, I need a practice, and a quiet space to allow things to unfold.

            I also can no longer justify turning away from what’s happening in the world. I don’t feel comfortable simply sitting with my discomfort, because not acting, not speaking up, not resisting allows the horror to continue.

            How do you reconcile these things? How do you balance peacefulness within, and the need for quiet space and contemplation, with the urgent need to act on behalf of peace (for all) in this world? If you feel it, how are you managing this tension these days?`,
        numberOfResponses: 41,
        postLink:
            "https://community.banyantogether.com/c/feed/how-do-you-make-peace-with-what-s-happening-in-the-world",
        userPictureUrl:
            "https://firebasestorage.googleapis.com/v0/b/cloudsangha.appspot.com/o/avatars%2FD6hWAldfmFBfEM5T6Edq.jpg?alt=media&token=5da21d40-8283-491a-a361-26d0686b9328",
    },
    {
        memberName: "Neil Baker",
        question: `Anxiety has always been problematic for me as it can lead to a spiraling into more extreme emotions and thinking that I have come to see are most likely triggered by childhood wounds.

          I would sure love to get rid of anxiety absolutely and completely😊. Of course, I know this is antithetical to the teachings in Banyan and I have benefitted greatly from welcoming anxiety and letting it speak to me and exploring it. At the same time, my mind still gets caught in some loops about it.

          I have the impression that some spiritual and psychological teachers/writers say that “no anxiety” is the aspiration. For example, when we are in Presence, that there is no anxiety. The Dalai Lama says there is no use for anxiety whatsoever (though, now that I think of it, his actual word is “worry” which may or may not imply something different than “anxiety”). One spiritual teacher from a childhood trauma background says that if we still have anxiety then that is an indication we are caught in childhood wounds and we need to keep “working on it” if we want to find our inner Loving Adult.

          I have noted that when I am in a state that seems to be close to Presence, Love, and calm, it is tinged with sadness—I have interpreted this to be “bodhicitta”—this Is from reading Pema Chodron—the “soft heart of sadness” that accepts our inherent vulnerability in the world. But I also always have a tinge of anxiety.

          I am interested in further reflection on these aspects of anxiety. For example (1) is anxiety always an indication of some issue that needs attention to work out? (2) Could it be possible that anxiety is sometimes part of the “soft heart” of the vulnerability of life?

          Thanks in advance for your thoughts. `,
        numberOfResponses: 25,
        postLink:
            "https://community.banyantogether.com/c/feed/is-anxiety-always-an-indication-of-an-issue-to-work-out",
        userPictureUrl:
            "https://firebasestorage.googleapis.com/v0/b/cloudsangha.appspot.com/o/avatars%2FSmareaBpzl99K35O3EJE.jpg?alt=media&token=c8bf2a0d-3bd6-4a8b-aaf9-070b64d600fd",
    },
    {
        memberName: "Sevinj Aliyeva",
        question: `Hello everyone, I hope you’re doing well. A few weeks ago, I shared my struggles regarding my parents’ separation at an older age and how deeply it has been affecting me. Unfortunately, weeks have passed, and nothing has changed.

          My parents still live under the same roof but refuse to speak to each other. My mom underwent eye surgery last month, yet she completely shut my dad out of the process. She didn’t allow him to be involved in any way—not even to help with her medication. As her daughter, I stepped in as much as I could. I prepared her meals whenever I had time, checked in on her regularly, and even helped her bathe. At the same time, I tried to maintain boundaries when it came to their issues, knowing that I can’t fix their broken relationship.

          Now, I am traveling for work, and my mom left me a tearful voice message. She says she cries all the time, despite needing to avoid stress after her surgery. She wants to move back to her hometown and live alone. She insists that if no one helps her buy a ticket, she will do it herself. My brothers strongly oppose this because she would be extremely isolated there, making it difficult for us to reach or support her. The only way to get there quickly is by plane, which still takes over four hours. She is elderly, with numerous health issues, and I fear for her well-being if she follows through.

          I have tried everything—suggesting she rent another place nearby, see a therapist, or find alternative solutions—but she refuses every option. She says I am the only person she can confide in, and that statement alone weighs heavily on me. I don’t want to be her sole emotional outlet. Carrying this burden alone is overwhelming. It’s painful to watch her struggle while knowing she won’t take any steps to help herself.

          Today, I felt so much anger. Why won’t she open up to her sisters? Why won’t she seek professional help? I know she fears judgment, afraid that her relatives will gossip, but why does that responsibility fall on me? I feel suffocated by the weight of it all. And as much as I hate to admit this, sometimes I just want it all to stop. The pain, the suffering—both theirs and mine.

          This crisis has impacted every aspect of my life—my work, my own family, my mental state. I feel completely helpless. No one around me truly understands what I’m going through. My friends and even my husband listen, but they treat it like an anecdote rather than the emotional crisis that it is. They blame my family, as if assigning fault will somehow fix the situation. But it doesn’t.

          I don’t know what to do anymore. How do I navigate this without losing myself? How do I support my mother without being consumed by her pain? I feel trapped in a situation that is spiraling beyond my control, and I don’t know how to find peace in it. Any advice or perspective would mean so much.`,
        numberOfResponses: 26,
        postLink:
            "https://community.banyantogether.com/c/feed/any-advice-on-how-to-approach-my-mother-s-pain",
        userPictureUrl:
            "https://firebasestorage.googleapis.com/v0/b/cloudsangha.appspot.com/o/avatars%2FhMhilBEvrEbUedFxvRdd.jpg?alt=media&token=74194e96-a1ed-4dd0-a4fe-69ca3d8d6ade",
    },
    {
        memberName: "Sevinj Aliyeva",
        question: `Hi everyone,

        I hope your 2025 has started off well.

        For me, this year began with significant family and job-related challenges, and I wanted to share some of my feelings with you all here.

        My parents, who are both 65, are going through a very difficult time. My mom believes my dad is cheating on her and wants him to leave the house. My dad, however, can’t seem to accept the idea of divorce and spends his days crying while they still live under the same roof. This situation has taken a toll on my mom—she experiences physical symptoms like headaches and sudden fainting spells, but doctors say there’s no medical cause.

        My dad has asked to stay with me and my family, but I don’t feel capable of handling that right now. Whenever I’m alone with my parents, I end up caught in the middle, listening to their endless accusations against each other. I’ve tried setting boundaries, explaining that I can’t be their therapist, but it feels like they don’t understand. Drawing these boundaries over and over is exhausting.

        At the same time, I’m struggling with work. Bureaucratic challenges have made it hard to do my job, and I’ve been feeling increasingly isolated in my workplace. Before the family issues, it was somewhat tolerable, but now, just getting up and going to work feels unbearable.

        I’ve been attending therapy, but nothing seems to truly help at the moment. I’m sharing this here because I know this community values openness and understanding, and I deeply appreciate you taking the time to read my post.

        Thank you.`,
        numberOfResponses: 22,
        postLink:
            "https://community.banyantogether.com/c/feed/navigating-family-and-work-challenges",
        userPictureUrl:
            "https://firebasestorage.googleapis.com/v0/b/cloudsangha.appspot.com/o/avatars%2FhMhilBEvrEbUedFxvRdd.jpg?alt=media&token=74194e96-a1ed-4dd0-a4fe-69ca3d8d6ade",
    },
    {
        memberName: "Anonymous",
        question: `My wife has anxiety and depression and these days i am not sure what mood she is in on a daily basis. This makes it very difficult to have a normal conversation as i have to watch what i say all the time. Mainly because i am not sure how she will react even if it is something normal. The whole thing has been heightened by the fact that i lost my job a while ago and have had not much luck finding a new one. So she is the only one working which puts a lot of pressure on her. I have tried to be as supportive as possible but her reaction to anything i say makes it very hard to converse normally. So i mainly try and do as much self care as possible as that is within my control. Any suggestions on how i can do anything else?`,
        numberOfResponses: 7,
        postLink:
            "https://community.banyantogether.com/c/feed/my-wife-has-anxiety-and-depression-and-these-days",
        userPictureUrl: undefined,
    },
    {
        memberName: "Anonymous",
        question: `Hello. Thank you for taking my question. I’m not sure if this is the right place to ask but I expect others in the meditation community may have some experience or knowledge of this. I have been experiencing spontaneous body movements (shakes, tremors, twitching, etc). Sometimes they are very strong and will repeat for a good amount of time. It’s a powerful energy and I find it to be absolutely fascinating but I’m hoping to find some guidance. On my own, I’ve found ways to move or calm the energy (just by trying various things). Some tell me it’s the beginning of a kundalini awakening. I’d love to know what can help me better understand and move the energy. Just this week I began to have energetic body movements/reactions to sound - I can feel the energy building up in different parts of my body depending on the rhythm and tone of different sounds. I’m also finding that experiencing cold food or the touch of cold causes it as well. I have little control over what my body does in these moments. It’s best described by saying my body is being moved, because I am not consciously controlling the movements. I’m not afraid of it or concerned. It honestly feels like a gift. I would just like to better understand how I can work with it for my (and others) highest good. Thank you.`,
        numberOfResponses: 5,
        postLink:
            "https://community.banyantogether.com/c/feed/hello-thank-you-for-taking-my-question-i-m-not",
        userPictureUrl: undefined,
    },
];

export const ExampleQuestionsSection = () => {
    const randomQuestions = useMemo(() => _.sampleSize(EXAMPLE_QUESITON, 2), []);

    return (
        <>
            <Typography
                variant="h4"
                sx={{
                    textAlign: "center",
                    mt: {
                        xs: 2,
                        sm: 4,
                    },
                }}
            >
                Example community questions
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    flexDirection: {
                        xs: "column",
                        lg: "row",
                    },
                    px: {
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 0,
                    },
                    my: {
                        xs: 2,
                        sm: 3,
                    },
                }}
            >
                {randomQuestions.map((question) => (
                    <ExampleQuestionCard
                        key={question.postLink}
                        memberName={question.memberName}
                        question={question.question}
                        numberOfResponses={question.numberOfResponses}
                        postLink={question.postLink}
                        userPictureUrl={question.userPictureUrl}
                    />
                ))}
            </Box>
        </>
    );
};
