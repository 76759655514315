import { Sangha, SanghaSessionCycle } from "@app/shared/types";
import { firstSessionForScheduleAfterDate, nthMonthlyWeekdayForDate } from "@app/shared/utils";
import { Typography } from "@mui/material";
import { renderUserTimezone } from "features/member/community/shared";
import { getShortTimeString } from "features/member/sessions/sessionHelpers";
import _ from "lodash";
import { DateTime } from "luxon";

const dayOfWeek = (date: DateTime, timezone?: string) => date.setZone(timezone).toFormat("cccc");

export const SanghaDayAndTimeDescription = ({
    sangha,
    overrideStartTime,
    overrideEndTime,
    userTimeZone,
    renderTimeZone,
}: {
    sangha: Sangha;
    overrideStartTime?: DateTime;
    overrideEndTime?: DateTime;
    userTimeZone: string;
    renderTimeZone?: boolean;
}) => {
    const sanghaCycle = sangha.cycle;
    const sanghaTimeZone = sangha.timeZone;

    const getNextSession = () =>
        firstSessionForScheduleAfterDate(
            DateTime.fromISO(sangha.firstSessionDate),
            DateTime.local(),
            sanghaTimeZone,
            sanghaCycle,
        );

    const sessionStartTime = overrideStartTime || getNextSession();
    const sessionEndTime = overrideEndTime || sessionStartTime.plus({ minutes: sangha.duration });

    const localDayOfWeek = dayOfWeek(sessionStartTime, userTimeZone);

    const timeString = getShortTimeString(sessionStartTime, sessionEndTime, userTimeZone);

    const getDayDescription = () => {
        const weekOfMonth = nthMonthlyWeekdayForDate(sessionStartTime);

        const weekMap: Record<number, string> = {
            1: "first",
            2: "second",
            3: "third",
            4: "fourth",
            5: "fifth",
        };

        const twiceMap: Record<number, string> = {
            1: "first and third",
            2: "second and fourth",
            3: "first and third",
            4: "second and fourth",
            5: "",
        };

        switch (sanghaCycle) {
            case SanghaSessionCycle.weekly:
                return `${_.capitalize(localDayOfWeek)}s`;
            case SanghaSessionCycle.monthly:
                return `${_.capitalize(weekMap[weekOfMonth] || "")} ${localDayOfWeek}s`;
            case SanghaSessionCycle.twiceMonthly: {
                const twiceDescription = twiceMap[weekOfMonth] || "";
                return twiceDescription
                    ? `${_.capitalize(twiceDescription)} ${localDayOfWeek}s`
                    : `${_.capitalize(localDayOfWeek)}s`;
            }
            default:
                return `${_.capitalize(localDayOfWeek)}s`;
        }
    };

    return (
        <Typography variant="caption" sx={{ mb: 0 }}>
            {getDayDescription()}, {timeString}{" "}
            {renderTimeZone ? renderUserTimezone(userTimeZone) : ""}
        </Typography>
    );
};
