import { IconButton, Snackbar } from "@mui/material";
import { routes } from "app/routes";
import { theme } from "app/theme";
import { LinkButton } from "features/navigation/LinkButton";

interface SupportSnackbarProps {
    supportSnackbarOpen: boolean;
    setSupportSnackbarOpen: (open: boolean) => void;
}

export const ContactSupportSnackbar = (props: SupportSnackbarProps) => {
    const { supportSnackbarOpen, setSupportSnackbarOpen } = props;

    return (
        <Snackbar
            open={supportSnackbarOpen}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSupportSnackbarOpen(false)}
            message="This is for questions about your practice only. "
            color="secondary"
            sx={{
                "& .MuiSnackbarContent-root": {
                    backgroundColor: theme.palette.error500,
                    py: 0.5,
                    fontSize: theme.typography.pxToRem(18),
                    px: 3,
                    textAlign: { xs: "center", lg: "left" },
                },
                "& .MuiSnackbarContent-action": {
                    margin: { xs: "0 auto", lg: "0" },
                },
                "& .MuiSnackbarContent-message": {
                    width: { xs: "100%", lg: "auto" },
                },
            }}
            action={
                <>
                    <LinkButton variant="primary" size="small" openInNewTab to={routes.contactUs()}>
                        Email Support
                    </LinkButton>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSupportSnackbarOpen(false)}
                        sx={{ ml: 1 }}
                    >
                        <span className="material-symbols-rounded">close</span>
                    </IconButton>
                </>
            }
        />
    );
};
