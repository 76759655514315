import { useQuery } from "@apollo/client";
import { Session, SessionStatus, SessionType, Teacher } from "@app/shared/types";
import { Box, Chip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
    GRAPHQL_QUERY_TEACHER,
    GRAPHQL_QUERY_TEACHER_UPCOMING_EVENTS,
    GRAPHQL_QUERY_TEACHER_UPCOMING_EVENTS_PUBLIC,
    GRAPHQL_QUERY_UPCOMING_RSVPED_SESSIONS_FOR_USER,
} from "app/queries";
import { theme } from "app/theme";
import classNames from "classnames";
import { AccentBar } from "components/AccentBar";
import DocumentContext from "components/DocumentContext";
import { GenericErrorPage } from "components/GenericErrorPage";
import LoadingBar from "components/LoadingBar";
import PageWrapper from "components/PageWrapper";
import { selectHasLegacyCoreMembershipFeatures, selectIsLoggedIn } from "features/auth/auth";
import { NavLink } from "features/navigation/NavLink";
import { useFetchDataAndKeepUpdated } from "hooks/useFetchDataAndKeepUpdated";
import { useUserTimezone } from "hooks/useUserTimezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import CommunityEventCard from "../community/CommunityEventCard";

const useStyles = makeStyles((theme) => ({
    locationIcon: {
        fontSize: theme.typography.pxToRem(20),
    },
    teacherImage: {
        borderRadius: theme.borderRadius.default,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        objectFit: "cover",
        height: 300,
        width: 350,
        [theme.breakpoints.down("md")]: {
            height: 250,
            width: 300,
            margin: "0 auto",
        },
    },
    infoIcon: {
        fontSize: theme.typography.pxToRem(20),
        color: theme.palette.grey700,
    },
    infoText: {
        display: "flex",
        alignItems: "center",
        gap: 1,
        [theme.breakpoints.up("xs")]: {
            justifyContent: "center",
        },
        [theme.breakpoints.up("md")]: {
            justifyContent: "flex-start",
        },
    },
}));

export const TeacherPage = () => {
    const classes = useStyles();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isCoreMember = useSelector(selectHasLegacyCoreMembershipFeatures);

    const { teacherId } = useParams<{ teacherId: string }>();
    const { data: teacherData, loading: teacherLoading } = useQuery(GRAPHQL_QUERY_TEACHER, {
        variables: {
            id: teacherId,
        },
    });

    const { data: rsvpedSessionData, refetch: refetchRsvpedSessions } = useQuery(
        GRAPHQL_QUERY_UPCOMING_RSVPED_SESSIONS_FOR_USER,
        {
            fetchPolicy: "network-only",
            skip: !isLoggedIn,
        },
    );

    const upcomingRsvpedSessionsForUser = rsvpedSessionData?.upcomingRsvpedSessionsForUser as {
        id: string;
    }[];

    const timezone = useUserTimezone();
    const includeTypes =
        isCoreMember || !isLoggedIn
            ? [
                  SessionType.SANGHA_SESSION,
                  SessionType.INTEREST_GROUP,
                  SessionType.COMMUNITY_SIT,
                  SessionType.COMMUNITY_TALK,
                  SessionType.SILENT_SIT,
                  SessionType.CONFLICT_RESOLUTION_PRACTICE,
                  SessionType.QA_SESSION,
              ]
            : [SessionType.SANGHA_SESSION, SessionType.QA_SESSION];

    const [sessionsQuery, setSessionsQuery] = useState(
        isLoggedIn
            ? GRAPHQL_QUERY_TEACHER_UPCOMING_EVENTS
            : GRAPHQL_QUERY_TEACHER_UPCOMING_EVENTS_PUBLIC,
    );

    useEffect(() => {
        setSessionsQuery(
            isLoggedIn
                ? GRAPHQL_QUERY_TEACHER_UPCOMING_EVENTS
                : GRAPHQL_QUERY_TEACHER_UPCOMING_EVENTS_PUBLIC,
        );
    }, [isLoggedIn]);

    const { data: sessionsData, loading: sessionsLoading } = useFetchDataAndKeepUpdated({
        query: sessionsQuery,
        options: {
            variables: {
                teacherId,
                includeTypes,
                statuses: [SessionStatus.PUBLISHED],
                sessionsRetrievalWindowInMinutes: 2 * 60 * 24 * 7, // next 2 weeks
            },
            fetchPolicy: "cache-and-network",
        },
        pollIntervalInMinutes: 30,
    });

    if (teacherLoading || sessionsLoading) {
        return (
            <PageWrapper>
                <LoadingBar />
            </PageWrapper>
        );
    }

    const teacher = teacherData?.teacher as Teacher;
    const sessions = sessionsData?.upcomingSessionsForTeacher as Session[];

    if (!teacher) {
        return (
            <GenericErrorPage alternateInstruction="We could not find the teacher you are looking for. Please" />
        );
    }

    return (
        <div>
            <DocumentContext title={teacher.name} addBrandNameSuffix />
            <PageWrapper data-testid="teacherPage">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: {
                            xs: 2,
                            md: 8,
                        },
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                    }}
                >
                    <img
                        src={teacher?.picture || undefined}
                        alt={teacher?.name}
                        className={classes.teacherImage}
                    />

                    <Box sx={{ flex: 1 }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: {
                                    xs: "column",
                                    md: "row",
                                },
                                flexWrap: "wrap",
                            }}
                        >
                            <Typography variant="h1" sx={{ mb: { xs: 0 }, mr: { xs: 0, md: 2 } }}>
                                {teacher?.name}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: theme.palette.grey700 }}>
                                {teacher?.pronouns}
                            </Typography>
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                color: theme.palette.grey700,
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                justifyContent: {
                                    xs: "center",
                                    md: "left",
                                },
                            }}
                        >
                            <span
                                className={"material-symbols-rounded"}
                                style={{ fontSize: theme.typography.pxToRem(18) }}
                            >
                                location_on
                            </span>
                            <strong>{teacher?.location}</strong>
                        </Typography>
                        {teacher?.specialties && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 1,
                                    justifyContent: { xs: "center", md: "left" },
                                    mb: 3,
                                }}
                            >
                                {teacher.specialties.map((specialty) => (
                                    <Chip key={specialty} label={specialty} variant="green" />
                                ))}
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                gap: { xs: 0, md: 8 },
                                flexDirection: {
                                    xs: "column",
                                    md: "row",
                                },
                            }}
                        >
                            <Typography variant="caption" className={classes.infoText}>
                                <span
                                    className={classNames(
                                        "material-symbols-rounded",
                                        classes.infoIcon,
                                    )}
                                >
                                    folded_hands
                                </span>
                                {teacher?.yearsMeditating} years meditating
                            </Typography>
                            <Typography variant="caption" className={classes.infoText}>
                                <span
                                    className={classNames(
                                        "material-symbols-rounded",
                                        classes.infoIcon,
                                    )}
                                >
                                    play_arrow
                                </span>
                                {teacher?.yearsTeaching} years teaching
                            </Typography>
                        </Box>
                        {!!teacher?.previewVideoLink &&
                            teacher.previewVideoLink.name !== "" &&
                            teacher.previewVideoLink.url !== "" && (
                                <NavLink
                                    to={teacher?.previewVideoLink?.url}
                                    style={{ textDecoration: "none" }}
                                    openInNewTab
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            borderRadius: `${theme.borderRadius.default}px`,
                                            backgroundColor: theme.palette.grey100,
                                            padding: 2,
                                            display: "flex",
                                            gap: 2,
                                            color: theme.palette.primaryLeaves,
                                            mt: 2,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                backgroundColor: theme.palette.neutralWarm,
                                                width: 80,
                                                height: 80,
                                                borderRadius: `${theme.borderRadius.small}px`,
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <span
                                                className={"material-symbols-rounded"}
                                                style={{
                                                    fontSize: theme.typography.pxToRem(40),
                                                    color: theme.palette.primaryLeaves,
                                                }}
                                            >
                                                play_arrow
                                            </span>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ mb: 0 }}>
                                                Watch now
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 0 }}>
                                                <strong>{teacher?.previewVideoLink?.name}</strong>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </NavLink>
                            )}
                    </Box>
                </Box>
                <AccentBar />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                        gap: {
                            xs: 2,
                            md: 4,
                        },
                    }}
                >
                    <Box sx={{ whiteSpace: "pre-line" }}>
                        <Typography variant="h3">Bio</Typography>
                        <Typography variant="h6">About me</Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            {teacher?.background}
                        </Typography>
                        <Typography variant="h6">I love working with</Typography>
                        <Typography variant="body1">{teacher?.loveWorkingWith}</Typography>
                        {!!teacher?.links && teacher.links.length > 0 && (
                            <>
                                <Typography variant="h6">Where you can find me</Typography>
                                <Typography variant="body1" sx={{ display: "flex", gap: 2 }}>
                                    {teacher?.links?.map((link) => (
                                        <NavLink to={link.url} key={link.name} openInNewTab>
                                            {link.name}
                                        </NavLink>
                                    ))}
                                </Typography>
                            </>
                        )}
                    </Box>
                    {!!teacher.certifications && teacher.certifications.length > 0 && (
                        <>
                            <Box
                                sx={{
                                    borderRadius: `${theme.borderRadius.default}px`,
                                    border: `1px solid ${theme.palette.grey300}`,
                                    padding: 4,
                                    width: {
                                        xs: "100%",
                                        md: 300,
                                    },
                                    minWidth: 300,
                                    height: "fit-content",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: theme.palette.primaryLeaves,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        mb: 2,
                                    }}
                                >
                                    <span
                                        className={"material-symbols-rounded"}
                                        style={{
                                            fontSize: theme.typography.pxToRem(20),
                                        }}
                                    >
                                        school
                                    </span>
                                    Certifications
                                </Typography>
                                {teacher?.certifications?.map((certification) => (
                                    <>
                                        <Typography key={certification.name} variant="caption">
                                            <strong>{certification.name}</strong>
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: theme.palette.grey700,
                                                display: "block",
                                                mb: 2,
                                            }}
                                        >
                                            {certification.institution} -{" "}
                                            {certification.yearOfCompletion}
                                        </Typography>
                                    </>
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
                {sessions?.length > 0 && (
                    <Box sx={{ mt: { xs: 4, md: 6 } }}>
                        <Typography variant="h3">Sessions I am facilitating</Typography>
                        <Box
                            sx={{
                                marginTop: theme.spacing(1),
                                display: "grid",
                                gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
                                gap: "22px",
                                [theme.breakpoints.up("md")]: {
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                },
                                [theme.breakpoints.up("lg")]: {
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                },
                            }}
                        >
                            {sessions?.map((session) => (
                                <CommunityEventCard
                                    isPublic={!isLoggedIn}
                                    key={session.id}
                                    event={session}
                                    timezone={timezone}
                                    userRsvpedToSession={upcomingRsvpedSessionsForUser?.some(
                                        (rsvpedSession) => rsvpedSession.id === session.id,
                                    )}
                                    onAttend={refetchRsvpedSessions}
                                    sangha={session.sangha}
                                />
                            ))}
                        </Box>
                    </Box>
                )}
            </PageWrapper>
        </div>
    );
};
