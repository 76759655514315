import { Discount, MembershipBillingCycle, MembershipType } from "./types";

export const getMentorshipGroupAddOnPrice = (
    groupType: MembershipType,
    billingCycle: MembershipBillingCycle,
) => {
    const isAnnualPlan = billingCycle === MembershipBillingCycle.year;

    switch (groupType) {
        case MembershipType.general:
        case MembershipType.immersion:
            return isAnnualPlan ? 1032 : 101;
        case MembershipType.immersion_twice_monthly:
            return 49;
        case MembershipType.mmtcp:
            return isAnnualPlan ? 312 : 26;
        default:
            throw new Error(`Invalid type: ${groupType}`);
    }
};

export const discountAmount = (basePrice: number, discount?: Omit<Discount, "endDate"> | null) => {
    const percentOff = discount?.percentOff;
    const amountOff = discount?.amountOff;

    if (percentOff) {
        return basePrice * (percentOff / 100);
    } else if (amountOff) {
        return amountOff / 100;
    } else {
        return 0;
    }
};

export const priceAfterDiscount = (
    basePrice: number,
    discount: Omit<Discount, "endDate"> | null | undefined,
) => {
    return basePrice - discountAmount(basePrice, discount);
};
