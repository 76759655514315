import { Video } from "@app/shared/types";
import { Avatar, Box, Divider, Typography, Button, useMediaQuery } from "@mui/material";
import { theme } from "app/theme";
import { LinkButton } from "features/navigation/LinkButton";
import { useState } from "react";
import _ from "lodash";

interface VideoAnswerSectionProps {
    answerVideo: Video;
}

export const VideoAnswerSection = (props: VideoAnswerSectionProps) => {
    const { answerVideo } = props;

    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

    const characterLimit = isMobileView ? 170 : 370;

    const [expanded, setExpanded] = useState(false);
    const truncatedText = answerVideo.originalQuestion
        ? _.truncate(answerVideo.originalQuestion, {
              length: characterLimit,
              separator: /,? +/,
          })
        : "";

    return (
        <>
            <Typography variant="body1" sx={{ mb: 2 }}>
                {answerVideo.matchExplanation}
            </Typography>
            <Box
                sx={{
                    border: theme.border.thin,
                    borderRadius: `${theme.borderRadius.default}px`,
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "rgba(26, 66, 62, 0.10)",
                        py: 2,
                        px: 4,
                        borderTopRightRadius: `${theme.borderRadius.default}px`,
                        borderTopLeftRadius: `${theme.borderRadius.default}px`,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Avatar
                            src={answerVideo.originalQuestionMemberPictureUrl || undefined}
                            sx={{ width: 25, height: 25, mr: 1 }}
                        />
                        <Typography variant="caption" sx={{ mb: 0, fontWeight: 700 }}>
                            {answerVideo.originalQuestionMemberName}
                        </Typography>
                        <Typography
                            variant="body3"
                            sx={{ ml: 1, color: theme.palette.grey700, mb: 0 }}
                        >
                            Member
                        </Typography>
                    </Box>

                    <Typography variant="caption" sx={{ mt: 1, mb: 0.5, display: "block" }}>
                        {expanded ? answerVideo.originalQuestion : truncatedText}
                    </Typography>
                    {answerVideo.originalQuestion.length > characterLimit && (
                        <Button
                            variant="tertiary"
                            onClick={() => setExpanded(!expanded)}
                            sx={{
                                color: theme.palette.grey700,
                                textDecoration: "none",
                                textTransform: "none",
                                fontWeight: 600,
                            }}
                        >
                            {expanded ? "See less" : "See more"}
                        </Button>
                    )}
                </Box>
                <Box sx={{ py: 2, px: 4 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Avatar
                            src={answerVideo.teacherPictureUrl || undefined}
                            sx={{ width: 25, height: 25, mr: 1 }}
                        />
                        <Typography variant="caption" sx={{ mb: 0 }}>
                            {answerVideo.teacherName}
                        </Typography>
                        <Typography variant="body3" sx={{ ml: 1, color: theme.palette.grey700 }}>
                            Teacher
                        </Typography>
                    </Box>
                    <Typography variant="caption" sx={{ my: 1, display: "block" }}>
                        {answerVideo.teacherAnswerText}
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: theme.borderRadius.default,
                            overflow: "hidden",
                            paddingTop: "56.25%", // simulates 16:9 aspect ratio
                            position: "relative",
                            width: "100%",
                        }}
                    >
                        <iframe
                            src={`https://player.vimeo.com/video/${answerVideo.vimeoVideoId}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                            allow="fullscreen"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                border: "none",
                            }}
                            title="Answer"
                        ></iframe>
                    </div>
                </Box>
                {answerVideo.postUrl && (
                    <>
                        <Divider sx={{ my: 0 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", py: 1, px: 3 }}>
                            <LinkButton variant="tertiary" to={answerVideo.postUrl} openInNewTab>
                                View full post
                            </LinkButton>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};
