import { OnboardingItemType, User } from "@app/shared/types";

export const getCompletedOnboardingItems = (user: User) => {
    // Since we removed the welcome sessions from the onboarding steps, we filter it out here for the users that already completed it
    return (
        user.profile.completedOnboardingItems?.filter(
            (item) => 
                item !== OnboardingItemType.FIRST_WELCOME_SESSION &&
                item !== OnboardingItemType.FIRST_TALK,
        ) || []
    );
};
