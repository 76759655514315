import { Sangha, Session } from "@app/shared/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ImmersionGroupCard } from "../community/ImmersionGroupCard";
import { separateGroups } from "../sessions/sessionHelpers";

interface MentorshipGroupListProps {
    groups: Sangha[];
    sessions: Session[];
    hideLockedGroups?: boolean;
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gap: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

export const MentorshipGroupList = (props: MentorshipGroupListProps) => {
    const { groups, sessions, hideLockedGroups } = props;
    const classes = useStyles();

    const { availableGroups, frozenGroups } = separateGroups(groups);

    return (
        <>
            {availableGroups.length > 0 && (
                <div className={classes.cardContainer}>
                    {availableGroups.map((group) => (
                        <ImmersionGroupCard key={group.id} sangha={group} />
                    ))}
                </div>
            )}
            {frozenGroups.length > 0 && !hideLockedGroups && (
                <>
                    <Typography variant="h2" sx={{ mb: 0 }}>
                        Locked groups
                    </Typography>
                    <Typography variant="body1">
                        To foster group stability, we lock groups for 4 weeks when new members join.
                    </Typography>
                    <div className={classes.cardContainer}>
                        {frozenGroups.map((group) => (
                            <ImmersionGroupCard
                                key={group.id}
                                sangha={group}
                                matchedSession={sessions.find(
                                    (session) => session.id === group.nextSession?.id,
                                )}
                                isFrozenGroup
                            />
                        ))}
                    </div>
                </>
            )}
            {availableGroups.length === 0 && (frozenGroups.length === 0 || hideLockedGroups) && (
                <Typography variant="h5" sx={{ mt: 4, textAlign: "center" }}>
                    No groups available with the current filters applied.
                </Typography>
            )}
        </>
    );
};
