import { ArrowBack } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { routes } from "app/routes";
import { theme } from "app/theme";
import { useHistory } from "react-router";

interface BackArrowButtonProps {
    route?: string;
    size?: number;
    onClick?: () => void;
}

export const BackArrowButton = (props: BackArrowButtonProps) => {
    const { route = routes.memberHomePage(), size = 35, onClick } = props;
    const history = useHistory();

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            history.push(route);
        }
    };

    return (
        <Tooltip title="Go back">
            <IconButton onClick={handleClick} aria-label="Go back">
                <ArrowBack sx={{ fontSize: theme.typography.pxToRem(size) }} />
            </IconButton>
        </Tooltip>
    );
};
