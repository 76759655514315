import { Sangha } from "@app/shared/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

interface SpotsAvailableInfoProps {
    sangha: Sangha;
}

const useStyles = makeStyles((theme) => ({
    membersCountContainer: {
        display: "flex",
        alignItems: "center",
    },
    membersCountIcon: {
        width: 20,
        height: 20,
        fontSize: theme.typography.pxToRem(20),
        marginRight: theme.spacing(0.5),
        verticalAlign: "middle",
    },
    membersCountText: {
        display: "inline-block",
        verticalAlign: "middle",
        whiteSpace: "nowrap",
        marginBottom: 0,
    },
}));

export const SpotsAvailableInfo = (props: SpotsAvailableInfoProps) => {
    const { sangha } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.membersCountContainer)}>
            <span className={classNames("material-symbols-rounded", classes.membersCountIcon)}>
                group
            </span>
            <Typography variant="caption" className={classes.membersCountText}>
                {sangha?.availableSpots ?? 0} of{" "}
                {sangha.maxSpots ||
                    (sangha?.memberships?.length ?? 0) + (sangha?.availableSpots ?? 0)}{" "}
                spots available
            </Typography>
        </div>
    );
};
