import { Sangha, Session } from "@app/shared/types";
import { useUserTimezone } from "hooks/useUserTimezone";
import { GRAPHQL_QUERY_PUBLIC_SESSION_BY_ID } from "app/queries";
import { useQuery } from "@apollo/client";
import CommunityEventCard from "./CommunityEventCard";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import { useSelector } from "react-redux";
import { selectCanAccessMemberZone } from "features/auth/auth";

interface ImmersionGroupCardProps {
    sangha: Sangha;
    matchedSession?: Session;
    isFrozenGroup?: boolean;
}

export const ImmersionGroupCard = (props: ImmersionGroupCardProps) => {
    const { sangha, matchedSession, isFrozenGroup } = props;

    const isBanyanMember = useSelector(selectCanAccessMemberZone);
    const timezone = useUserTimezone();

    const { data, loading, error } = useQuery(GRAPHQL_QUERY_PUBLIC_SESSION_BY_ID, {
        variables: { sessionId: sangha.nextSession?.id },
        skip: !!matchedSession || !sangha.nextSession,
    });

    const session = matchedSession || data?.sessionById;

    if (loading) {
        return <CircularProgressContainer />;
    }

    if (error) {
        return <GenericErrorMessage />;
    }

    return (
        <CommunityEventCard
            event={session}
            timezone={timezone}
            userRsvpedToSession={false}
            sangha={sangha}
            isPublic={!isBanyanMember}
            showSanghaCard
            isFrozenGroup={isFrozenGroup}
        />
    );
};

export default ImmersionGroupCard;
