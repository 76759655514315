import {
    PeerGroupTimeSlot,
    PeerGroupTimeSlotInUserTimeZone,
    PeerGroupTimeSlotInUserTimeZoneOrUTC,
} from "@app/shared/types";
import { DateTime, WeekdayNumbers } from "luxon";

export const convertPeerGroupTimeSlotUTCToUserTimeZone = (
    peerGroupTimeSlot: PeerGroupTimeSlot,
    userTimeZone: string,
): PeerGroupTimeSlotInUserTimeZone => {
    const { weekday, startTimeUTC, cycle } = peerGroupTimeSlot;

    const parsedStartTimeUTC = DateTime.fromFormat(startTimeUTC, "H:mm", {
        zone: "UTC",
    });

    const nowUTC = DateTime.now().setZone("UTC");

    if (!parsedStartTimeUTC.isValid) {
        throw new Error("Invalid time input");
    }

    const anchorUTC = nowUTC.set({
        weekday: weekday,
        hour: parsedStartTimeUTC.hour,
        minute: parsedStartTimeUTC.minute,
        second: 0,
        millisecond: 0,
    });

    const userDateTime = anchorUTC.setZone(userTimeZone);

    const convertedWeekdayNumber = userDateTime.weekday as WeekdayNumbers;
    const convertedStartTime = userDateTime.toFormat("HH:mm");

    return {
        weekday: convertedWeekdayNumber,
        startTimeInUserTimeZone: `${convertedStartTime}`,
        cycle,
    } as PeerGroupTimeSlotInUserTimeZone;
};

export const convertPeerGroupTimeSlotUserTimeZoneToUTC = (
    peerGroupTimeSlot: PeerGroupTimeSlotInUserTimeZone,
    userTimeZone: string,
): PeerGroupTimeSlot => {
    const { weekday, startTimeInUserTimeZone, cycle } = peerGroupTimeSlot;

    const parsedStartTimeUserTimeZone = DateTime.fromFormat(startTimeInUserTimeZone, "H:mm", {
        zone: userTimeZone,
    });

    const userTimeZoneUTC = DateTime.now().setZone(userTimeZone);

    if (!parsedStartTimeUserTimeZone.isValid) {
        throw new Error("Invalid time input");
    }

    const anchorUserTimeZone = userTimeZoneUTC.set({
        weekday: weekday,
        hour: parsedStartTimeUserTimeZone.hour,
        minute: parsedStartTimeUserTimeZone.minute,
        second: 0,
        millisecond: 0,
    });

    const utcDateTime = anchorUserTimeZone.setZone("UTC");

    const convertedWeekdayNumber = utcDateTime.weekday as WeekdayNumbers;
    const convertedStartTime = utcDateTime.toFormat("HH:mm");

    return {
        weekday: convertedWeekdayNumber,
        startTimeUTC: `${convertedStartTime}`,
        cycle,
    } as PeerGroupTimeSlot;
};

export const formatPeerGroupTimeSlot = (
    peerGroupTimeSlot: PeerGroupTimeSlotInUserTimeZoneOrUTC | null,
    timezone: string,
    show24hTimeFormat?: boolean,
) => {
    if (!peerGroupTimeSlot) {
        return "";
    }

    const startTime =
        "startTimeInUserTimeZone" in peerGroupTimeSlot
            ? peerGroupTimeSlot.startTimeInUserTimeZone
            : peerGroupTimeSlot.startTimeUTC;

    const timezoneOffset = DateTime.local().setZone(timezone).offsetNameShort;

    const startTimeInDateTime =
        "startTimeInUserTimeZone" in peerGroupTimeSlot
            ? DateTime.fromFormat(startTime, "HH:mm", { zone: timezone })
            : DateTime.fromFormat(startTime, "HH:mm", { zone: "UTC" }).setZone(timezone);

    const startTimeIn12hFormat = startTimeInDateTime.toFormat("h:mm a");
    const startTimeIn24hFormat = startTimeInDateTime.toFormat("HH:mm");

    return `${DateTime.fromObject({
        weekday: peerGroupTimeSlot.weekday,
    }).toFormat(
        "cccc",
    )}, ${startTimeIn12hFormat}${show24hTimeFormat ? ` (${startTimeIn24hFormat})` : ""} ${timezoneOffset} (${peerGroupTimeSlot.cycle})`;
};

export const convertAndFormatPeerGroupTimeSlotToUserTimeZone = (
    peerGroupTimeSlot: PeerGroupTimeSlot | null,
    timezone: string,
    show24hTimeFormat?: boolean,
) => {
    if (!peerGroupTimeSlot) {
        return "";
    }

    const convertedTimeSlot = convertPeerGroupTimeSlotUTCToUserTimeZone(
        peerGroupTimeSlot,
        timezone,
    );

    return formatPeerGroupTimeSlot(convertedTimeSlot, timezone, show24hTimeFormat);
};

export const getDayFromWeekdayNumber = (weekdayNumber: WeekdayNumbers) => {
    return DateTime.fromObject({
        weekday: weekdayNumber,
    }).toFormat("cccc");
};

export const getPeerGroupTimeSlotId = (timeSlot: PeerGroupTimeSlot): string =>
    `${timeSlot.weekday}-${timeSlot.startTimeUTC}-${timeSlot.cycle}`;
