import { Avatar, Container, Dialog, Divider, Modal, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { externalImageSrc } from "app/images";
import { selectCanAccessMemberZone, selectUserProfile } from "features/auth/auth";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { useDispatch, useSelector } from "react-redux";
import { MenuLink } from "./MenuLink";
import { closeMenu, selectIsMenuOpen } from "./navigationSlice";
import { LinkTag, MenuLinkInfo, useNavigationLinks } from "./useNavigationLinks";
import { LinkButton } from "./LinkButton";
import { routes } from "app/routes";
import { analyticsTrack } from "app/analytics/track";
import { theme } from "app/theme";

const useStyles = makeStyles((theme) => ({
    fullPageMenu: {
        top: 100,
        zIndex: theme.zIndex.modal + 1,
    },
    menuItemList: {
        listStyleType: "none",
        paddingInlineStart: 0,
        "& .MuiTypography-root.MuiLink-root": {
            color: theme.palette.grey900,

            "&:hover": {
                color: theme.palette.accentEarthy,
            },
        },
    },
    menuContainer: {
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: theme.palette.neutralWhite,
        outline: "none",
        pointerEvents: "auto",
        padding: theme.spacing(0, 2),
        height: "100%",
        overflowY: "auto",
    },
    accountMenu: {
        position: "relative",
        marginBottom: theme.spacing(2),
    },
    avatar: {
        borderRadius: theme.borderRadius.circular,
        width: 26,
        height: 26,
        border: `2px solid ${theme.palette.accentEarthy}`,
        position: "absolute",
        top: 7,
    },
    profileLink: {
        paddingLeft: 33,
        [theme.breakpoints.up("sm")]: {
            paddingLeft: 35,
        },
    },
    divider: {
        opacity: 0.3,
    },
}));

export const FullPageMenu = (props: any) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const menuIsOpen = useSelector(selectIsMenuOpen);
    const hamburgerMenuVisible = useBreakpointQuery("lg");
    const userProfile = useSelector(selectUserProfile);

    const isBanyanMember = useSelector(selectCanAccessMemberZone);

    const links = useNavigationLinks(true);

    const topLinks = links.filter(
        (link) => link.name !== "Resources" && !link.tags.includes(LinkTag.ACCOUNT_LINKS),
    );
    const resourcesLink = links.filter((link) => link.name === "Resources");
    const accountMenuLinks = links.filter((link) => link.tags.includes(LinkTag.ACCOUNT_LINKS));

    const renderLink = (link: MenuLinkInfo) => {
        return (
            <li key={link.name}>
                <Typography variant="h4">
                    <MenuLink
                        link={link}
                        isFullPageMenu={true}
                        onClick={() => dispatch(closeMenu())}
                        onClickSubNav={() => dispatch(closeMenu())}
                    />
                </Typography>
                <Divider
                    sx={{
                        borderColor: theme.palette.grey900,
                        my: 2,
                    }}
                />
            </li>
        );
    };

    const renderAccountMenu = (accountLinks: MenuLinkInfo[]) => {
        const profileLink: MenuLinkInfo = {
            name: "Profile",
            to: "#",
            tags: [LinkTag.ACCOUNT_LINKS],
            subNavLinks: accountLinks,
        };
        return (
            <li className={classes.accountMenu}>
                <Typography
                    variant="h4"
                    style={{
                        marginBottom: 0,
                        textTransform: "none",
                    }}
                >
                    <Avatar
                        className={classes.avatar}
                        alt={userProfile?.fullName}
                        src={
                            userProfile?.picture
                                ? externalImageSrc(userProfile?.picture, 55)
                                : undefined
                        }
                    />
                    <MenuLink
                        link={profileLink}
                        isFullPageMenu={true}
                        onClick={() => dispatch(closeMenu())}
                        onClickSubNav={() => dispatch(closeMenu())}
                        className={classes.profileLink}
                    />
                </Typography>
            </li>
        );
    };

    return (
        <Modal
            open={hamburgerMenuVisible && menuIsOpen}
            onClose={() => dispatch(closeMenu())}
            hideBackdrop
            className={classes.fullPageMenu}
        >
            <Container maxWidth="lg" className={classes.menuContainer}>
                <Divider
                    sx={{
                        borderColor: theme.palette.grey900,
                        my: 1,
                    }}
                />
                <ul className={classes.menuItemList}>{topLinks.map(renderLink)}</ul>

                <ul className={classes.menuItemList}>
                    {resourcesLink.map(renderLink)}
                    {accountMenuLinks.length > 0 && renderAccountMenu(accountMenuLinks)}
                </ul>
                {userProfile && isBanyanMember && (
                    <LinkButton
                        variant="primary"
                        sx={{
                            textTransform: "none !important",
                            my: 2,
                            fontSize: `${theme.typography.pxToRem(20)} !important`,
                            fontWeight: 700,
                        }}
                        fullWidth
                        to={routes.askTeacherPage()}
                        onClick={() => {
                            analyticsTrack("ask.teacher.button.clicked");
                            dispatch(closeMenu());
                        }}
                    >
                        Ask us anything
                    </LinkButton>
                )}
                {!userProfile && (
                    <>
                        <LinkButton
                            to={routes.signup()}
                            variant="primary"
                            fullWidth
                            onClick={() => dispatch(closeMenu())}
                            sx={{
                                textTransform: "none !important",
                                mb: 2,
                                fontSize: `${theme.typography.pxToRem(20)} !important`,
                                fontWeight: 700,
                            }}
                        >
                            Try for free
                        </LinkButton>
                        <LinkButton
                            to={routes.login()}
                            variant="secondary"
                            fullWidth
                            onClick={() => dispatch(closeMenu())}
                            sx={{
                                textTransform: "none !important",
                                fontSize: `${theme.typography.pxToRem(20)} !important`,
                                fontWeight: 700,
                            }}
                        >
                            Login
                        </LinkButton>
                    </>
                )}
            </Container>
        </Modal>
    );
};
