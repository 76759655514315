import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Drawer,
    IconButton,
    Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { theme } from "app/theme";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import { MarkdownRenderer } from "./MarkdownRenderer";
import { useRef, useState } from "react";
import { analyticsTrack } from "app/analytics/track";
import { User } from "@app/shared/types";
import { useMutation } from "@apollo/client";
import { GRAPHQL_MUTATION_CREATE_POST_ON_CIRCLE } from "app/queries";
import { LinkButton } from "features/navigation/LinkButton";
import { GenericErrorMessage } from "components/GenericErrorMessage";

enum PostVisibility {
    Public = "public",
    Anonymous = "anonymous",
}

interface ReviewPostDrawerProps {
    open: boolean;
    onClose: () => void;
    question: string;
    user: User;
    isAnalyzingQuestion: boolean;
    aiQuestionAnalysis: string | null;
    isMobile: boolean;
    onSubmit: () => void;
    setQuestion: (question: string) => void;
    analyzeQuestionWithAi: (question?: string, previousContext?: string) => Promise<void>;
}

export const ReviewPostDrawer = (props: ReviewPostDrawerProps) => {
    const {
        open,
        onClose,
        question,
        user,
        isAnalyzingQuestion,
        aiQuestionAnalysis,
        isMobile,
        onSubmit,
        setQuestion,
        analyzeQuestionWithAi,
    } = props;

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [postURL, setPostURL] = useState<string | null>(null);
    const [urlError, setUrlError] = useState<boolean>(false);
    const [postVisibility, setPostVisibility] = useState<PostVisibility>(PostVisibility.Public);
    const isPostingAnonymous = postVisibility === PostVisibility.Anonymous;
    const postedQuestion = useRef<string | null>(null);

    const [isEditing, setIsEditing] = useState(false);
    const [draftQuestion, setDraftQuestion] = useState(question);

    const handleEditClick = () => {
        analyticsTrack("question.preview.edit.clicked");
        setDraftQuestion(question);
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        const previousQuestion = question;

        setQuestion(draftQuestion);
        setIsEditing(false);

        await analyzeQuestionWithAi(
            draftQuestion,
            aiQuestionAnalysis
                ? `Previous question: ${previousQuestion}, previous guidance: ${aiQuestionAnalysis}`
                : undefined,
        );
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setDraftQuestion(question);
    };

    const handlePostVisibilityChange = (
        event: React.MouseEvent<HTMLElement>,
        newPostVisibility: PostVisibility | null,
    ) => {
        if (newPostVisibility !== null) {
            setPostVisibility(newPostVisibility);
        }
    };

    const [createPostOnCircleMutation, { loading: isSending, error: circlePostError }] =
        useMutation(GRAPHQL_MUTATION_CREATE_POST_ON_CIRCLE, {
            variables: {
                content: question,
                anonymous: postVisibility === PostVisibility.Anonymous,
            },
        });

    const onPostingQuestionToCircle = async () => {
        analyticsTrack("post.question.clicked", { question });
        postedQuestion.current = question;
        const result = await createPostOnCircleMutation();
        const postURL = result.data.createPostOnCircle;
        if (!postURL) {
            setUrlError(true);
            return;
        }
        setPostURL(postURL);
        setSnackbarOpen(true);
        onClose();
        onSubmit();
    };

    return (
        <>
            <Drawer
                open={open}
                onClose={onClose}
                anchor="right"
                PaperProps={{ sx: { maxWidth: 620 } }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        boxShadow: theme.shadow.default,
                    }}
                >
                    <IconButton onClick={onClose}>
                        <span className="material-symbols-rounded">chevron_left</span>
                    </IconButton>
                    <Typography variant="h5">Review Post</Typography>
                </Box>
                <Box sx={{ p: 3 }}>
                    <Typography variant="h3" sx={{ mb: 0 }}>
                        Post in the lounge
                    </Typography>
                    <Typography variant="caption">
                        {isPostingAnonymous
                            ? "Your question will be automatically posted in The Lounge. Because your post is anonymous you will not be notified when a teacher answers you. Please check the replies of your post manually."
                            : "Your question will be automatically posted in The Lounge and you'll be notified when a teacher has answered you."}
                    </Typography>
                    <ToggleButtonGroup
                        exclusive
                        value={postVisibility}
                        onChange={handlePostVisibilityChange}
                        color="secondary"
                        sx={{ mt: 1 }}
                    >
                        <ToggleButton
                            value={PostVisibility.Public}
                            sx={{
                                display: "flex",
                                gap: { xs: 0.5, md: 1 },
                                alignItems: "center",
                            }}
                        >
                            <span className="material-symbols-rounded">visibility</span>Public Post
                        </ToggleButton>
                        <ToggleButton
                            value={PostVisibility.Anonymous}
                            sx={{
                                display: "flex",
                                gap: { xs: 0.5, md: 1 },
                                alignItems: "center",
                            }}
                        >
                            <span className="material-symbols-rounded">comedy_mask</span>
                            Anonymous Post
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.neutralGold,
                            borderRadius: `${theme.borderRadius.small}px`,
                            p: 2,
                            mt: 2,
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
                            <Avatar
                                src={
                                    isPostingAnonymous
                                        ? undefined
                                        : user.profile.picture || undefined
                                }
                                sx={{ width: 18, height: 18 }}
                            />
                            <Typography variant="caption" sx={{ mb: 0 }}>
                                By {isPostingAnonymous ? "Anonymous" : `${user.profile.fullName}`}
                            </Typography>
                        </Box>

                        {isEditing ? (
                            <>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={draftQuestion}
                                    onChange={(e) => setDraftQuestion(e.target.value)}
                                    size="small"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            mb: 1.5,
                                            fontSize: theme.typography.pxToRem(20),
                                            fontWeight: 700,
                                        },
                                    }}
                                    multiline
                                    maxRows={8}
                                />
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    <Button
                                        variant="primary"
                                        size="small"
                                        onClick={handleSaveClick}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="small"
                                        onClick={handleCancelClick}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: 700, whiteSpace: "pre-line" }}
                                >
                                    {question}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    sx={{ color: (theme) => theme.palette.grey[700] }}
                                >
                                    Draft
                                </Typography>
                            </>
                        )}
                    </Box>
                    {question === postedQuestion.current && !isSending && (
                        <Typography
                            variant="caption"
                            sx={{
                                color: theme.palette.warning500,
                                display: "block",
                                mt: 1,
                            }}
                        >
                            You already posted this question. Please edit your question so you can
                            post again.
                        </Typography>
                    )}
                    {isAnalyzingQuestion && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress sx={{ mt: 3, mb: 2 }} />
                        </Box>
                    )}
                    {aiQuestionAnalysis && !isAnalyzingQuestion && (
                        <Box
                            sx={{
                                mt: 2,
                                backgroundColor: theme.palette.neutralWarm,
                                borderRadius: `${theme.borderRadius.small}px`,
                                p: 2,
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <span
                                className="material-symbols-rounded"
                                style={{
                                    color: `${theme.palette.accentEarthy}`,
                                    fontSize: `${theme.typography.pxToRem(32)}`,
                                }}
                            >
                                lightbulb_2
                            </span>
                            <Typography variant="caption" sx={{ mb: 0 }}>
                                <MarkdownRenderer markdown={aiQuestionAnalysis} />
                            </Typography>
                        </Box>
                    )}
                    {urlError && (
                        <Typography variant="body1" color="error" sx={{ mt: 4 }}>
                            <GenericErrorMessage
                                alternateInstruction="An error occured while posting your question. Please reload the page and try
                                        again. If that doesn't work, please"
                            />
                        </Typography>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 3,
                            gap: 2,
                            flexDirection: {
                                xs: "column",
                                sm: "row",
                            },
                        }}
                    >
                        <Button variant="secondary" onClick={handleEditClick} fullWidth={isMobile}>
                            Edit
                        </Button>

                        <Button
                            variant="primary"
                            onClick={onPostingQuestionToCircle}
                            fullWidth={isMobile}
                            disabled={question === postedQuestion.current || isSending}
                        >
                            Post
                        </Button>
                    </Box>
                </Box>
            </Drawer>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setSnackbarOpen(false)}
                message="Your question was posted on the Lounge 🎉"
                color="secondary"
                sx={{
                    "& .MuiSnackbarContent-root": {
                        backgroundColor: theme.palette.success500,
                        py: 0.5,
                        fontSize: theme.typography.pxToRem(18),
                        px: 3,
                        textAlign: { xs: "center", lg: "left" },
                    },
                    "& .MuiSnackbarContent-action": {
                        margin: { xs: "0 auto", lg: "0" },
                    },
                    "& .MuiSnackbarContent-message": {
                        width: { xs: "100%", lg: "auto" },
                    },
                }}
                action={
                    <>
                        <LinkButton variant="primary" size="small" to={postURL || ""} openInNewTab>
                            View post
                        </LinkButton>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setSnackbarOpen(false)}
                            sx={{ ml: 1 }}
                        >
                            <span className="material-symbols-rounded">close</span>
                        </IconButton>
                    </>
                }
            />
        </>
    );
};
