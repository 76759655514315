import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { theme } from "app/theme";
import PageWrapper from "components/PageWrapper";
import { TypedQuestions } from "./TypedQuestions";
import { useRef } from "react";
import { analyticsTrack } from "app/analytics/track";

interface QandAHeroSectionProps {
    question: string;
    isMobile: boolean;
    setQuestion: (question: string) => void;
    handleFetchingVideo: () => void;
    loading: boolean;
}

export const QandAHeroSection = (props: QandAHeroSectionProps) => {
    const { question, isMobile, setQuestion, handleFetchingVideo, loading } = props;

    const startedTyping = useRef<boolean>(false);

    const disableSending = !question || loading;

    const handleTextFieldTyping = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion(event.target.value);
        if (!startedTyping.current) {
            analyticsTrack("question.typing.started");
            startedTyping.current = true;
        }
    };

    return (
        <Box sx={{ backgroundColor: "#F7F9F6" }}>
            <PageWrapper removeMarginTop>
                <Box
                    sx={{
                        textAlign: "center",
                        maxWidth: 1000,
                        margin: "0 auto",
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: theme.palette.grey100,
                            p: 2,
                            borderRadius: 0.5,
                            mb: 0,
                        }}
                    >
                        <Box sx={{ mb: 2 }}>
                            <img
                                src="https://assets.cloudsangha.co/images/icons/Graphic-Proven-Practices.svg"
                                style={{ width: "100%", maxWidth: 50 }}
                            />
                        </Box>
                        <Typography variant="h2" sx={{ mb: 0 }}>
                            <TypedQuestions />
                        </Typography>
                        <Typography variant="body1">
                            We'll share previous answers that match your question.
                        </Typography>
                    </Box>
                    <TextField
                        value={question}
                        placeholder="Ask us anything"
                        fullWidth
                        autoFocus={!isMobile}
                        onChange={handleTextFieldTyping}
                        multiline
                        size="small"
                        maxRows={8}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: 0.5,
                                mb: { xs: 1, sm: 2 },
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ mr: 2 }}>
                                    <span className="material-symbols-rounded">help</span>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        color="secondary"
                                        size="small"
                                        sx={{ p: 0.3 }}
                                        disabled={disableSending}
                                        onClick={handleFetchingVideo}
                                    >
                                        <span
                                            className="material-symbols-rounded"
                                            style={{
                                                fontSize: theme.typography.pxToRem(30),
                                            }}
                                        >
                                            chevron_right
                                        </span>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </PageWrapper>
        </Box>
    );
};
