import { SanghaMembership } from "@app/shared/types";
import { Typography } from "@mui/material";
import { LoggedInUserProfileContext } from "features/auth/AuthRoute";
import { DateTime } from "luxon";
import { useContext } from "react";
import { SanghaFirstSessionDate } from "./SanghaFirstSessionDate";
import { useUserTimezone } from "hooks/useUserTimezone";

export interface SanghaMembershipPendingProps {
    sanghaMembership: SanghaMembership;
    title?: string;
    additionalText?: string;
}

export const SanghaMembershipPending = (props: SanghaMembershipPendingProps) => {
    const { sanghaMembership } = props;

    const timeZone = useUserTimezone();

    const startDate = DateTime.fromISO(sanghaMembership.startDate, {
        zone: timeZone,
    });

    return (
        <>
            <Typography variant="h2">
                {props.title ?? "Welcome to your mentorship group!"}
            </Typography>
            <Typography variant="body1">
                Your first session will be on <br />
                <SanghaFirstSessionDate sanghaMembership={sanghaMembership} timeZone={timeZone} />.
            </Typography>

            <Typography variant="body1">
                After {startDate.toLocaleString({ day: "numeric", month: "long" })}, you’ll be able
                to see your group details here. {props.additionalText}
            </Typography>
        </>
    );
};
