import { SanghaAudienceDisplayMap } from "@app/shared/constants";
import {
    InterestGroupTopic,
    Sangha,
    Session,
    SessionType,
    Teacher,
    UserProfile,
} from "@app/shared/types";
import { GRAPHQL_QUERY_UPCOMING_EVENTS } from "app/queries";
import { SessionTypeColors } from "app/theme";
import { useCurrentServerTime } from "hooks/useCurrentServerTime";
import { useFetchDataAndKeepUpdated } from "hooks/useFetchDataAndKeepUpdated";
import { useUserTimezone } from "hooks/useUserTimezone";
import _ from "lodash";
import { DateTime } from "luxon";
import { useQueryParams } from "use-query-params";
import { renderFilterCheckboxList } from "../interestGroups/InterestGroupFilter";
import { sortSimplifiedTeachersByLastName, sortTeachersByLastName } from "../teachers/sortTeachers";
import { CustomArrayParam } from "./customQueryParamDefinitions";
import { isSanghaFromSessionFrozen } from "../sessions/sessionHelpers";

export enum TimeOfDayOptions {
    any = "any time of the day",
    morning = "mornings",
    afternoon = "afternoons",
    evening = "evenings",
}

export const timeOfDayOptions: FilterDataOption[] = [
    { label: "Morning", value: TimeOfDayOptions.morning },
    { label: "Afternoon", value: TimeOfDayOptions.afternoon },
    { label: "Evening", value: TimeOfDayOptions.evening },
];

export type FilterDataOption = {
    label: string;
    value: any;
    type?: string;
    color?: { default: string; light: string };
    audience?: string;
};

export const getWeekDayIndex = (weekdayName: string) => {
    const index = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
    ].indexOf(weekdayName.toLowerCase());

    return index > -1 ? index + 1 : null;
};

export const dayOfWeekOptions: FilterDataOption[] = [
    { label: "Monday", value: "monday" },
    { label: "Tuesday", value: "tuesday" },
    { label: "Wednesday", value: "wednesday" },
    { label: "Thursday", value: "thursday" },
    { label: "Friday", value: "friday" },
    { label: "Saturday", value: "saturday" },
    { label: "Sunday", value: "sunday" },
];

const timeWindowMap: Record<TimeOfDayOptions, { start: number; end: number }> = {
    [TimeOfDayOptions.any]: { start: 0, end: 24 },
    [TimeOfDayOptions.morning]: { start: 6, end: 12 },
    [TimeOfDayOptions.afternoon]: { start: 12, end: 17 },
    [TimeOfDayOptions.evening]: { start: 17, end: 21 },
};

const getTimeFromGroup = (
    group: Sangha,
    timezone: string,
    firstSessionDate?: boolean,
): DateTime => {
    if (firstSessionDate) {
        return group.firstSessionDate
            ? DateTime.fromISO(group.firstSessionDate, {
                  zone: timezone,
              })
            : DateTime.fromISO("");
    }

    return group.nextSession?.time
        ? DateTime.fromISO(group.nextSession?.time, {
              zone: timezone,
          })
        : DateTime.fromISO("");
};

export const groupMatchesTimeOfDay = (
    group: Sangha,
    selectedTimeOfDay: TimeOfDayOptions,
    timezone: string,
    useFirstSessionDate?: boolean,
) => {
    if (selectedTimeOfDay === TimeOfDayOptions.any) {
        return true;
    }
    const timeWindow = timeWindowMap[selectedTimeOfDay];

    return (
        getTimeFromGroup(group, timezone, useFirstSessionDate).hour >= timeWindow.start &&
        getTimeFromGroup(group, timezone, useFirstSessionDate).hour < timeWindow.end
    );
};

const getTimeFromSession = (session: Session, timezone: string): DateTime => {
    return session.time
        ? DateTime.fromISO(session.time, {
              zone: timezone,
          })
        : DateTime.fromISO("");
};

export const sessionMatchesTimeOfDay = (
    session: Session,
    selectedTimeOfDay: TimeOfDayOptions,
    timezone: string,
) => {
    if (selectedTimeOfDay === TimeOfDayOptions.any) {
        return true;
    }
    const timeWindow = timeWindowMap[selectedTimeOfDay];

    return (
        getTimeFromSession(session, timezone).hour >= timeWindow.start &&
        getTimeFromSession(session, timezone).hour < timeWindow.end
    );
};

export const filterSessions = (
    events: Session[],
    query: any,
    timezone: string,
    currentUserProfile?: UserProfile,
    rsvpedSessions?: { id: string }[],
    loggedInTeacherId?: string,
    frozenGroupIds?: string[],
) => {
    let filteredSessions = [...events];

    const {
        timeOfDay,
        dayOfWeek,
        teacher,
        sessionType,
        onlyMyEvents,
        hideLockedGroups,
        topic,
        audience,
    } = query;

    if (timeOfDay && timeOfDay.length > 0) {
        filteredSessions = filteredSessions.filter((x) =>
            _.some(timeOfDay, (timeOfDayItem) =>
                sessionMatchesTimeOfDay(x, timeOfDayItem as TimeOfDayOptions, timezone),
            ),
        );
    }

    if (dayOfWeek && dayOfWeek.length > 0) {
        const weekdayIndexes = _.compact(
            dayOfWeek.map((dayName: string) => getWeekDayIndex(dayName)),
        );
        filteredSessions = filteredSessions.filter((x) =>
            _.includes(weekdayIndexes, DateTime.fromISO(x.time).weekday),
        );
    }

    if (teacher && teacher.length > 0) {
        filteredSessions = filteredSessions.filter(
            (session) =>
                session.teachers[0].id && _.includes(query.teacher, session.teachers[0].id),
        );
    }

    if (sessionType && sessionType.length > 0) {
        filteredSessions = filteredSessions.filter((session) =>
            _.includes(query.sessionType, session.type),
        );
    }

    if (onlyMyEvents && onlyMyEvents.length > 0) {
        filteredSessions = filteredSessions.filter(
            (session) =>
                session.sanghaMembership ||
                (rsvpedSessions &&
                    rsvpedSessions.some(
                        (rsvpedSession: { id: string }) => rsvpedSession.id === session.id,
                    )) ||
                (loggedInTeacherId &&
                    session.teachers.some((teacher) => teacher.id === loggedInTeacherId)),
        );
    }

    if (hideLockedGroups && hideLockedGroups.length > 0) {
        filteredSessions = filteredSessions.filter(
            (session) => !isSanghaFromSessionFrozen(session, frozenGroupIds),
        );
    }

    if (topic && topic.length > 0) {
        filteredSessions = filteredSessions.filter(
            (session) =>
                session.sangha?.topicData?.id &&
                _.includes(query.topic, session.sangha.topicData.id),
        );
    }

    if (audience && audience.length > 0) {
        filteredSessions = filteredSessions.filter(
            (session) =>
                session.sangha?.audienceDisplay &&
                _.includes(query.audience, session.sangha.audienceDisplay),
        );
    }

    return filteredSessions;
};

export const filterSanghas = (events: Sangha[], query: any, timezone: string) => {
    let filteredSanghas = [...events];

    const { timeOfDay, dayOfWeek, teacher, topic, audience, sessionType } = query;

    if (timeOfDay && timeOfDay.length > 0) {
        filteredSanghas = filteredSanghas.filter((x) =>
            _.some(timeOfDay, (timeOfDayItem) =>
                groupMatchesTimeOfDay(x, timeOfDayItem as TimeOfDayOptions, timezone),
            ),
        );
    }

    if (dayOfWeek && dayOfWeek.length > 0) {
        const weekdayIndexes = _.compact(
            dayOfWeek.map((dayName: string) => getWeekDayIndex(dayName)),
        );
        filteredSanghas = filteredSanghas.filter((sangha) =>
            _.includes(
                weekdayIndexes,
                DateTime.fromISO(sangha.nextSession?.time || sangha.firstSessionDate).weekday,
            ),
        );
    }

    if (teacher && teacher.length > 0) {
        filteredSanghas = filteredSanghas.filter(
            (sangha) => sangha.teacher.id && _.includes(query.teacher, sangha.teacher.id),
        );
    }

    if (topic && topic.length > 0) {
        filteredSanghas = filteredSanghas.filter(
            (sangha) => sangha.topicData && _.includes(query.topic, sangha.topicData.id),
        );
    }

    if (audience && audience.length > 0) {
        filteredSanghas = filteredSanghas.filter(
            (sangha) =>
                sangha.audienceDisplay && _.includes(query.audience, sangha.audienceDisplay),
        );
    }

    if (sessionType && sessionType.length > 0) {
        filteredSanghas = filteredSanghas.filter((sangha) =>
            _.includes(query.sessionType, sangha.type),
        );
    }

    return filteredSanghas;
};

export const useFetchAndFilterSessions = (includeTypes: SessionType[]) => {
    const currentServerTime = useCurrentServerTime();
    const timezone = useUserTimezone();

    const [query, setQuery] = useQueryParams({
        timeOfDay: CustomArrayParam,
        dayOfWeek: CustomArrayParam,
        teacher: CustomArrayParam,
    });

    const resetFilters = () => {
        setQuery({
            timeOfDay: undefined,
            dayOfWeek: undefined,
            teacher: undefined,
        });
    };

    const { data, loading, error } = useFetchDataAndKeepUpdated({
        query: GRAPHQL_QUERY_UPCOMING_EVENTS,
        options: {
            variables: {
                includeTypes,
            },
        },
        pollIntervalInMinutes: 30,
    });

    let events = data ? (Object.values(data)[0] as Session[]) : undefined;

    const filteredSessions = events ? filterSessions(events, query, timezone) : undefined;

    return {
        currentServerTime,
        query,
        setQuery,
        resetFilters,
        data,
        loading,
        error,
        events,
        filteredSessions,
    };
};

export const getTeacherFilterOptionsFromSanghas = (groups: Sangha[]) => {
    const interestGroupTeachers = groups.map((sangha: Sangha) => sangha.teacher);
    const uniqueTeachers = _.uniqBy(interestGroupTeachers, "id");
    const sortedTeachers = sortTeachersByLastName(uniqueTeachers);

    const teacherOptions = sortedTeachers.map((teacher) => ({
        value: teacher.id,
        label: teacher.name,
    }));
    return teacherOptions;
};

export const getTeacherFilterOptionsFromSessions = (sessions: Session[]) => {
    const interestGroupTeachers = sessions.map((session: Session) => session.teachers[0]);
    const uniqueTeachers = _.uniqBy(interestGroupTeachers, "id");
    const sortedTeachers = sortSimplifiedTeachersByLastName(uniqueTeachers);

    const teacherOptions = sortedTeachers.map((teacher) => ({
        value: teacher.id,
        label: teacher.name,
    }));
    return teacherOptions;
};

export const getTeacherSpecialtiesFilterOptionsFromTeachers = (teachers: Teacher[]) => {
    if (!teachers || teachers.length === 0) {
        return null;
    }

    const teacherSpecialties = _.flatten(
        teachers.map((teacher: Teacher) => teacher.specialties || []),
    );

    if (teacherSpecialties.length === 0) {
        return null;
    }

    const uniqueSpecialties = _.uniq(teacherSpecialties);

    const teacherSpecialtiesOptions = uniqueSpecialties.map((specialty) => ({
        value: specialty,
        label: specialty,
    }));
    return teacherSpecialtiesOptions;
};

export const getAudienceFilterOptionsFromSanghas = (groups: Sangha[]) => {
    const audiences = _.uniq(groups.map((group: Sangha) => group.audienceDisplay));
    const audienceOptions = audiences.map((audience) => ({
        value: audience,
        label: SanghaAudienceDisplayMap[audience] || audience,
    }));
    return audienceOptions;
};

export const useInterestGroupTopicFilterOptionsFromSangha = (sanghas: Sangha[]) => {
    if (!sanghas) {
        return [];
    }

    const topics = sanghas
        .filter((sangha: Sangha) => !!sangha.topicData)
        .map((sangha: Sangha) => ({
            ...sangha.topicData,
            audience: sangha.audienceDisplay,
        }));
    const uniqueTopics = _.uniqBy(topics, "id");
    const sortedTopics = _.sortBy(uniqueTopics, "name");

    const topicOptions = sortedTopics
        .filter((topic) => !!topic)
        .map((topic) => ({
            value: topic.id as string,
            label: topic.name as string,
            audience: topic.audience,
        }));
    return topicOptions;
};

export const useInterestGroupTopicFilterOptionsFromSessions = (sessions: Session[]) => {
    if (!sessions) {
        return [];
    }

    const hasSanghaAndTopicData = (
        session: Session,
    ): session is Session & { sangha: { topicData: InterestGroupTopic; audience: string } } => {
        return !!session.sangha && !!session.sangha.topicData;
    };

    const topics = sessions.filter(hasSanghaAndTopicData).map((session) => ({
        ...session.sangha.topicData,
        audience: session.sangha.audienceDisplay,
    }));

    const uniqueTopics = _.uniqBy(topics, "id");
    const sortedTopics = _.sortBy(uniqueTopics, "name");

    const topicOptions = sortedTopics.map((topic) => ({
        value: topic.id,
        label: topic.name,
        audience: topic.audience,
    }));
    return topicOptions;
};

export const useInterestGroupTopicFilterConfig = (
    filters: FilterData,
    setFilters: (filters: FilterData) => void,
    topicOptions: FilterDataOption[],
    disabled?: boolean,
) => {
    if (!filters.topic) {
        return null;
    }

    return {
        title: "Topic",
        render: renderFilterCheckboxList({
            items: topicOptions,
            selectedValues: filters.topic,
            onChange: (topic: string[]) => setFilters({ ...filters, topic }),
            disabled,
        }),
        getAppliedCount: () => filters.topic?.length ?? 0,
        openByDefault: filters.topic?.length > 0,
    };
};

export const getDayOfWeekFilterConfig = (
    filters: FilterData,
    setFilters: (filters: FilterData) => void,
) => {
    if (!filters.dayOfWeek) {
        return null;
    }

    return {
        title: "Day of the week",
        render: renderFilterCheckboxList({
            items: dayOfWeekOptions,
            selectedValues: filters.dayOfWeek,
            onChange: (dayOfWeek: string[]) => setFilters({ ...filters, dayOfWeek }),
        }),
        getAppliedCount: () => filters.dayOfWeek?.length ?? 0,
        openByDefault: filters.dayOfWeek?.length > 0,
    };
};

export const getTimeOfDayFilterConfig = (
    filters: FilterData,
    setFilters: (filters: FilterData) => void,
) => {
    if (!filters.timeOfDay) {
        return null;
    }

    return {
        title: "Period of the day",
        render: renderFilterCheckboxList({
            items: timeOfDayOptions,
            selectedValues: filters.timeOfDay,
            onChange: (timeOfDay: string[]) => setFilters({ ...filters, timeOfDay }),
        }),
        getAppliedCount: () => filters.timeOfDay?.length ?? 0,
        openByDefault: filters.timeOfDay?.length > 0,
    };
};

export const getTeacherFilterConfig = (
    filters: FilterData,
    setFilters: (filters: FilterData) => void,
    teacherOptions: FilterDataOption[],
    disabled?: boolean,
) => {
    if (!filters.teacher) {
        return null;
    }

    return {
        title: "Teacher",
        render: renderFilterCheckboxList({
            items: teacherOptions,
            selectedValues: filters.teacher,
            onChange: (teacher: string[]) => setFilters({ ...filters, teacher }),
            disabled,
        }),
        getAppliedCount: () => filters.teacher?.length ?? 0,
        openByDefault: filters.teacher?.length > 0,
    };
};

export const getTeacherSpecialtyFilterConfig = (
    filters: FilterData,
    setFilters: (filters: FilterData) => void,
    teacherSpecialtiesOptions: FilterDataOption[],
    disabled?: boolean,
) => {
    if (!filters.specialties) {
        return null;
    }

    return {
        title: "Specialties",
        render: renderFilterCheckboxList({
            items: teacherSpecialtiesOptions,
            selectedValues: filters.specialties,
            onChange: (specialties: string[]) => setFilters({ ...filters, specialties }),
            disabled,
        }),
        getAppliedCount: () => filters.specialties?.length ?? 0,
        openByDefault: true,
    };
};

export const getAudienceFilterConfig = (
    filters: FilterData,
    setFilters: (filters: FilterData) => void,
    audienceOptions: FilterDataOption[],
) => {
    if (!filters.audience) {
        return null;
    }

    return {
        title: "Audience",
        render: renderFilterCheckboxList({
            items: audienceOptions,
            selectedValues: filters.audience,
            onChange: (audience: string[]) => setFilters({ ...filters, audience }),
        }),
        getAppliedCount: () => filters.audience?.length ?? 0,
    };
};

export const sessionTypeOptions: FilterDataOption[] = [
    {
        label: "Mindful Friends Group",
        value: SessionType.INTEREST_GROUP,
        color: SessionTypeColors[SessionType.INTEREST_GROUP],
    },
    {
        label: "Q&A Session",
        value: SessionType.QA_SESSION,
        color: SessionTypeColors[SessionType.QA_SESSION],
    },
    {
        label: "Meditation",
        value: SessionType.COMMUNITY_SIT,
        color: SessionTypeColors[SessionType.COMMUNITY_SIT],
    },
];

export const getSessionTypeFilterConfig = (
    filters: FilterData,
    setFilters: (filters: FilterData) => void,
) => {
    if (!filters.sessionType) {
        return null;
    }

    const noOtherFiltersSelected =
        filters.teacher?.length === 0 &&
        filters.topic?.length === 0 &&
        filters.dayOfWeek?.length === 0 &&
        filters.timeOfDay?.length === 0;

    return {
        title: "Session Type",
        render: renderFilterCheckboxList({
            items: sessionTypeOptions,
            selectedValues: filters.sessionType,
            onChange: (sessionType: string[]) => setFilters({ ...filters, sessionType }),
        }),
        getAppliedCount: () => filters.sessionType?.length ?? 0,
        openByDefault: filters.sessionType?.length > 0 || noOtherFiltersSelected,
    };
};

export const getOnlyMyEventsFilterConfig = (
    filters: FilterData,
    setFilters: (filters: FilterData) => void,
    disabled?: boolean,
) => {
    if (!filters.onlyMyEvents) {
        return null;
    }

    return {
        title: "Only My Events",
        render: renderFilterCheckboxList({
            items: [{ label: "My Sessions Only", value: "true" }],
            selectedValues: filters.onlyMyEvents,
            onChange: (onlyMyEvents: string[]) => setFilters({ ...filters, onlyMyEvents }),
            disabled,
        }),
        getAppliedCount: () => filters.onlyMyEvents?.length ?? 0,
        showWithoutNesting: true,
    };
};

export const getHideLockedGroupsFilterConfig = (
    filters: FilterData,
    setFilters: (filters: FilterData) => void,
    disabled?: boolean,
) => {
    if (!filters.hideLockedGroups) {
        return null;
    }

    return {
        title: "Hide Locked Groups",
        render: renderFilterCheckboxList({
            items: [{ label: "Hide Locked Groups", value: "true" }],
            selectedValues: filters.hideLockedGroups,
            onChange: (hideLockedGroups: string[]) => setFilters({ ...filters, hideLockedGroups }),
            disabled,
        }),
        getAppliedCount: () => filters.hideLockedGroups?.length ?? 0,
        showWithoutNesting: true,
    };
};

export type FilterData = {
    timeOfDay?: string[];
    dayOfWeek?: string[];
    topic?: string[];
    status?: string[];
    teacher?: string[];
    audience?: string[];
    sessionType?: string[];
    onlyMyEvents?: string[];
    hideLockedGroups?: string[];
    specialties?: string[];
};
